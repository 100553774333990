import { getClassList } from "@/api/cls.js";
import { getCurrentClassOpenId, setCurrentClassOpenId } from "@/utils/auth.js";

// 班级

const state = {
  list: [],
  currentClassOpenId: null,
  currentClass: {},
  loadCallback: null,
  showClassPasscode: false,
};

const mutations = {
  // 保存列表
  SET_CLASS_LIST: (state, params = []) => {
    state.list = params;
  },

  SET_CLASS_OPENID: (state, id) => {
    state.currentClassOpenId = id;
    setCurrentClassOpenId(id);
  },

  SET_CURRENT_CLASS: (state, clz = {}) => {
    state.currentClass = clz || {};
  },

  SET_SHOE_CLASS_PASSCODE: (state, val) => {
    state.showClassPasscode = val;
  },

  SET_PASSCODE: (state, val) => {
    state.currentClass.Passcode = val;
  },
};

const actions = {
  // 获取班级列表
  loadClassList({ commit, state, rootState, dispatch }, params = {}) {
    return new Promise((resolve, reject) => {
      const { forceUpdate = false } = params; // 是否是强制更新最新数据
      if (state.list && state.list.length > 0 && !forceUpdate) {
        resolve({ code: 0 });
        return;
      }

      getClassList()
        .then((res) => {
          if (res && res.code === 0) {
            const list = JSON.parse(JSON.stringify(res.result || []));

            if (list.length == 0) {
              commit("SET_CLASS_LIST", []);
              commit("SET_CLASS_OPENID", null);
              commit("SET_CURRENT_CLASS", {});
              resolve(res);
              return;
            }

            let currentIndex = -1;
            let stateIndex = -1;
            let stateId = state.currentClassOpenId;

            let cookieIndex = -1;
            let cookieId = getCurrentClassOpenId();

            let canRead = false;
            let canPublic = false;
            for (let i = 0; i < list.length; i++) {
              if (stateId == list[i].OpenId) {
                stateIndex = i;
              }
              if (cookieId == list[i].OpenId) {
                cookieIndex = i;
              }
              list[i].IsEnded = classEnded(list[i]);
              list[i].Joined = parseInt(list[i].Joined);

              // 允许学生读其他的书
              if (list[i].ReadOtherBook != 0) {
                canRead = true;
              }
              // 允许学生公开发布书
              if (list[i].PublicBook != 0) {
                canPublic = true;
              }
            }

            if (stateIndex != -1) {
              currentIndex = stateIndex;
            }
            if (cookieIndex != -1) {
              currentIndex = cookieIndex;
            }

            if (currentIndex == -1) {
              // 当前班级ID不在列表里面，默认取第一条
              commit("SET_CLASS_OPENID", list[0].OpenId);
              commit("SET_CURRENT_CLASS", list[0]);
            } else {
              commit("SET_CLASS_OPENID", list[currentIndex].OpenId);
              commit("SET_CURRENT_CLASS", list[currentIndex]);
            }
            commit("SET_CLASS_LIST", list);

            // 设置班级学生权限
            const User = rootState.user || {};
            if (User.isClassStudent) {
              const vals = {
                ReadOtherBook: canRead,
                PublicBook: canPublic,
              };
              dispatch("user/setStudentPermission", vals, { root: true });
            }
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  reset({ commit }) {
    commit("SET_CLASS_LIST", []);
    commit("SET_CLASS_OPENID", null);
    commit("SET_CURRENT_CLASS", {});
    setCurrentClassOpenId(null);
  },

  setShowClassPasscode({ commit }, val) {
    commit("SET_SHOE_CLASS_PASSCODE", val);
  },

  setPasscode({ commit }, val) {
    commit("SET_PASSCODE", val);
  },

  setCurrentClassByOpenId({ commit, state }, openId) {
    const list = state.list || [];

    if (list.length == 0) {
      commit("SET_CLASS_OPENID", null);
      commit("SET_CURRENT_CLASS", {});
      setCurrentClassOpenId(null);
      return;
    }

    let currentIndex = -1;
    for (let i = 0; i < list.length; i++) {
      if (openId == list[i].OpenId) {
        currentIndex = i;
        break;
      }
    }
    if (currentIndex == -1) {
      commit("SET_CLASS_OPENID", list[0].OpenId);
      commit("SET_CURRENT_CLASS", list[0]);
      setCurrentClassOpenId(list[0].OpenId);
    } else {
      commit("SET_CLASS_OPENID", openId);
      commit("SET_CURRENT_CLASS", list[currentIndex]);
      setCurrentClassOpenId(openId);
    }
  },
};

// 班级是否结束
function classEnded(cls) {
  function addZero(n) {
    if (n < 10) {
      n = "0" + n;
    }
    return n;
  }
  if (cls.EndTime) {
    let d = cls.EndTime.split(" ")[0];
    let today = new Date();
    let todayD = today.getFullYear() + "-" + addZero(today.getMonth() + 1) + "-" + addZero(today.getDate());

    if (d.localeCompare(todayD) < 0) {
      return true;
    }
  }
  return false;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
