// 全局配置

const state = {
  showTeacherRecommand: true, // 显示首页老师推荐栏
};

const mutations = {
  SET_GLOBAL_VALUE: (state, params) => {
    if (params && "key" in params) {
      state[params.key] = params.value;
    }
  },
};

const actions = {
  setGlobalValue({ commit }, params) {
    commit("SET_GLOBAL_VALUE", params);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
