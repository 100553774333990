// 获取school plan价格
export function getSchoolPriceByNumbers(numbers) {
  const SchoolPrice = 8; // 折前原价$8/人
  let tp = 0;
  if (numbers <= 15) {
    // 15人内不打折
    tp = SchoolPrice * numbers;
  } else if (numbers <= 100) {
    // 超出15个人的部分打9折
    tp = 120 + (numbers - 15) * SchoolPrice * 0.9;
  } else if (numbers <= 200) {
    // 超出100个人的部分打85折
    tp = 732 + (numbers - 100) * SchoolPrice * 0.85;
  } else {
    // 人数超过限制, 提示联系我们
    tp = 0;
  }
  return tp;
}

// family plan价格
export function getFamilyPriceByNumbers() {
  // // 59.88 --> 29.99
  // const Price = 29.99;
  // let tp = 0;
  // if (numbers <= 2) {
  //   // 七折
  //   tp = Price * numbers * 0.7;
  // } else if (numbers <= 5) {
  //   // 第三人起六折
  //   tp = 41.83 + (numbers - 2) * Price * 0.6;
  // } else {
  //   // 人数超过限制, 提示联系我们
  //   tp = 0;
  // }

  // 已固定人数5，价格69.99
  const tp = 69.99;
  return tp;
}
