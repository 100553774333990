//name方式跳转页面、带查询参数
export function push(vm, path, query = {}) {
  if (!path || path.length === 0 || path === "index") {
    path = "/";
  }
  if (
    vm.$router.currentRoute &&
    (vm.$router.currentRoute.path === path || vm.$router.currentRoute.path === "/" + path) &&
    JSON.stringify(vm.$router.currentRoute.query) === JSON.stringify(query)
  ) {
    // 新页面与当前路径、参数一致，拦截跳转
    return;
  }
  if (path === "/") {
    // // 跳转到首页，需要清空路由历史，否则会存在很多次返回
    // let backlen = window.history.length - 2;
    // if (backlen > 0) {
    //   window.history.go(-backlen);
    // }
    vm.$router.replace({ path: path, query: query });
  } else {
    vm.$router.push({ path: path, query: query });
    //示例： router.push({ path: '/register', query: { plan: 'private' } })
  }
}
