import App from "./App.vue";
import router from "./router";
import store from "./store";
import { saveCookie } from "@/utils/auth.js";
import { getToken, getStbCookie } from "@/utils/auth.js";

//引入全局样式
import "@/assets/css/global.css";
import "@/assets/css/element-theme/index.css"; // 重置修改element-ui主题颜色
import "@/assets/css/quiz.css";
import "@/assets/css/alert.css";
import "@/assets/css/style.css"; // 新加的全局样式统一放这里
// 通用组件样式
import "@/assets/css/views/form.css"; // 表单样式
import "@/assets/css/views/button.css"; // button
import "@/assets/css/views/alert.css"; // button
// 页面通用样式
import "@/assets/css/page.css";

// 文言国际化
import i18n from "./i18n/index";

// 全局公共方法和基础业务参数
import BaseGlobal from "./utils/BaseGlobal";
Vue.use(BaseGlobal);

// 全局自定义组件
import components from "@/components/index";
Vue.use(components);

// // element-ui
// import Element from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
// import locale from "element-ui/lib/locale/lang/en";
// Vue.use(Element, { locale });

// import { DatePicker, TimePicker, TimeSelect } from "element-datepicker";
// Vue.component(DatePicker.name, DatePicker);
// Vue.component(TimePicker.name, TimePicker);
// Vue.component(TimeSelect.name, TimeSelect);

Vue.config.productionTip = false;

// 设置浏览器默认title
let title = "Storybird";
let desc = "Read, write, discover, and share the books you'll always remember.";
window.document.title = title + " - " + desc;

// 给数组添加一个move方法
Array.prototype.move = function (from, to) {
  if (to < 0) {
    return;
  }
  if (to >= this.length) {
    return;
  }
  this.splice(to, 0, this.splice(from, 1)[0]);
};

// 时区
import jstz from "jstz";
const timezone = jstz.determine();
const timeZone = timezone.name();
if (timeZone && timeZone != "Etc/Unknown" && timeZone != "UTC") {
  saveCookie("TimeZone", timeZone);
}

// 全局EventBus
Vue.prototype.$EventBus = new Vue();

// 异常捕捉
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";
// const SentryDsn = process.env.VUE_APP_SENTRY_DSN;
// // 只有dev、prod环境才捕捉
// if (SentryDsn) {
//   Sentry.init({
//     Vue,
//     dsn: SentryDsn,
//     integrations: [
//       new BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracePropagationTargets: ["dev.storybird.com", "storybird.com", /^\//],
//       }),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// }

// amplitude统计
// import { init } from "@amplitude/analytics-browser";
// init(process.env.VUE_APP_AMPLITUDE_APIKEY);
import { initAmplitude } from "@/plugins/log/LogEvent.js";
initAmplitude();

import plugins from "@/plugins/index.js";
Vue.use(plugins);

// youtube iframe api
import VuePlayerPlugin from "vue-youtube-iframe-api";
Vue.use(VuePlayerPlugin);

// 使用图片懒加载
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
});

// ELEMENT默认使用英文
ELEMENT.locale(ELEMENT.lang.en);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  created: () => {
    // 初始化数据
    store.dispatch("best/loadBestList");
    const token = getToken();
    let cookie = getStbCookie();
    if (token || cookie) {
      // 初始化数据放在loadUserInfo里面获取
    } else {
      store.dispatch("user/reset");
      store.dispatch("basket/clear");
      store.dispatch("notification/reset");
      store.dispatch("plan/clear");
      store.dispatch("class/reset");
      store.dispatch("awstoken/resetToken");
    }
  },
}).$mount("#app");
