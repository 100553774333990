import { getNotificationCount, getNotificationList } from "@/api/notification.js";

// 通知

const state = {
  notification: null,
  popNotification: [],
  popNotificationNextPage: [],
  notificationCount: 0,
  isLoading: false,
};

const mutations = {
  // 保存列表
  SET_NOTIFICATION_LIST: (state, list = []) => {
    state.notification = list;
  },

  // 保存未读通知数
  SET_NOTIFICATION_COUNT: (state, count = 0) => {
    state.notificationCount = count;
  },

  SET_NOTIFICATION_LOADED: (state, val = false) => {
    state.isLoading = val;
  },
};

const actions = {
  // 获取通知列表方法
  loadNotificationList({ commit }) {
    commit("SET_NOTIFICATION_LOADED", true);
    return new Promise((resolve, reject) => {
      getNotificationList()
        .then((res) => {
          if (res.code === 0) {
            const list = JSON.parse(JSON.stringify(res.result.NotificationList || []));
            commit("SET_NOTIFICATION_LIST", list);
            commit("SET_NOTIFICATION_COUNT", 0);
            commit("SET_NOTIFICATION_LOADED", false);
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          commit("SET_NOTIFICATION_LOADED", false);
          reject(err);
        });
    });
  },

  // 获取未读通知数量方法
  checkNotificationCount({ commit }) {
    return new Promise((resolve, reject) => {
      getNotificationCount()
        .then((res) => {
          const count = res.result.NotificationCount || 0;
          commit("SET_NOTIFICATION_COUNT", count);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  reset({ commit }) {
    commit("SET_NOTIFICATION_LIST", []);
    commit("SET_NOTIFICATION_COUNT", 0);
    commit("SET_NOTIFICATION_LOADED", false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
