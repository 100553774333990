import { userInfo } from "@/api/user.js";
import { getBookCount } from "@/api/yourstuff.js";
import { UpdateInfo } from "@/api/user.js";
import { getExpireBarClose, setToken, setExpireBarClose, setCurrentClassOpenId, deleteCookie } from "@/utils/auth.js";
import router from "@/router/index.js";
import { applyTheme } from "@/utils/theme.js";

// 用户类型
const rolesMap = {
  0: "Regular User",
  1: "Student",
  2: "Teacher",
  3: "Artist",
  4: "Writer",
  5: "Creative Partner",
  6: "Student",
  7: "Student",
  8: "Student",
  9: "Student", // class student
};

const state = {
  isLogin: false, // 用户数据是否处于登录状态
  username: "",
  avatar: "",
  email: "",
  lastLogin: "",
  dateJoined: "",
  accountType: "",
  originalAvatar: "",
  stripeCustomerId: "",
  largeAvatar: "",
  isMember: false,
  crown: 0,
  gift: 0,
  isStudent: false, // 学生类型账号
  isClassStudent: false, // 老师创建的学生账号
  notStudent: false,
  isTeacher: false,
  isArtist: false,
  ticket: 0,
  birthday: "",
  age: 0,
  ageGroup: 0,
  uid: 0,
  jwt: null,
  level: 0,
  theme: "",
  challengeId: 0,
  isActive: false,
  joinFromPreviewSite: false,
  searchKey: "",
  canManagePlan: false,
  managePlanOpenId: "",
  isInSchoolPlan: false,
  isInFamilyPlan: false,
  isNewBorn: false,
  familyPlanExpired: true,
  schoolPlanOpenId: "",
  familyPlanOpenId: "",
  barClosed: getExpireBarClose() == "1",
  bookCount: 0, // 用户写书的数量
  isInFreeTrial: false,
  Plans: [],
  PasswordInsecure: false,
  FreeTrialExpireTime: 0,
  MembershipTime: 0,
  Country: "",

  // 其他属性
  role: "", // 用户类型
  canReadComic: false,
  canReadPictureStory: false,
  canReadFlashFiction: false,
  canReadLongformStory: false,
  canReadPoetry: false,

  // 班级学生权限
  canReadOtherBook: true, // 允许浏览阅读模块
  canPublicBook: true, // 允许公开发布书本
};

const mutations = {
  // 保存用户信息
  SET_USER_INFO: (state, param) => {
    state.isLogin = true;
    state.uid = parseInt(param["Uid"]);
    state.username = param["UserName"];
    if (Vue.prototype.$BaseGlobal) {
      let url = Vue.prototype.$BaseGlobal.avatarUrl(param["Avatar"], param["UserName"]);
      state.avatar = url;
    } else {
      state.avatar = "";
    }
    state.email = param["Email"];
    state.isActive = param["IsActive"] == 0 ? false : true;

    // AccountType
    // 0 -> 学生， 1 -> Student(V1)，2 -> 老师，3 -> 艺术家，4 -> Writer，5 -> Creative Partner，
    // 6 -> Student not associated to an class(V1)，7 -> Student removed from class(V1)，
    // 8 -> Student who has converted to a regular account(V1)
    // 9 -> 老师创建的学生
    state.accountType = parseInt(param["AccountType"]);
    state.notStudent = state.accountType >= 2 && state.accountType < 6;
    state.isStudent = !state.notStudent;
    state.isClassStudent = state.accountType == 9;
    state.isTeacher = state.accountType == 2;
    state.isArtist = state.accountType == 3;
    state.role = rolesMap[state.accountType];

    // member
    state.isMember = param["IsMember"];
    state.FreeTrialExpireTime = param["FreeTrialExpireTime"];
    state.MembershipTime = param["MembershipTime"];
    state.isInFreeTrial = param["IsInFreeTrial"];

    state.lastLogin = param["LastLogin"];
    state.dateJoined = param["DateJoined"];
    state.originalAvatar = param["OriginalAvatar"];
    state.stripeCustomerId = param["StripeCustomerId"];
    state.largeAvatar = param["LargeAvatar"];
    state.crown = param["Crown"];
    state.gift = param["Gift"];
    state.ticket = param["Ticket"];
    state.birthday = param["Birthday"];
    state.theme = param["Theme"];

    // age
    state.age = param["Age"];
    state.ageGroup = 0;
    if (Vue.prototype.$BaseGlobal) {
      let Ages = Vue.prototype.$BaseGlobal.Ages;
      for (let x = 0; x < Ages.length; x++) {
        if (Ages[x].num <= parseInt(state.age)) {
          state.ageGroup = Ages[x].id;
        }
      }
    }

    state.level = param["Level"];
    state.joinFromPreviewSite = param["JoinFromPreviewSite"];
    state.isNewBorn = param["IsNewBorn"] ? true : false;
    state.barClosed = getExpireBarClose() == "1";
    state.Country = param["Country"];

    // plan
    let plans = param["Plans"];
    if (plans) {
      for (let i = 0; i < plans.length; i++) {
        if (plans[i].IsManager) {
          state.canManagePlan = true;
          state.managePlanOpenId = plans[i].OpenId;
        }
        if (plans[i].Type == 0) {
          state.isInFamilyPlan = true;
          state.familyPlanOpenId = plans[i].OpenId;
          let now = new Date();
          let expTimes = plans[i].ExpireTime.replace(/[ :]/g, "-").split("-");
          let date = new Date(expTimes[0], expTimes[1] - 1, expTimes[2], expTimes[3], expTimes[4], expTimes[5]);
          state.familyPlanExpired = now > date;
        }
        if (plans[i].Type > 0) {
          state.isInSchoolPlan = true;
          state.schoolPlanOpenId = plans[i].OpenId;
        }
      }
    }
    // 预防多个school plan时，schoolPlanOpenId被覆盖的问题
    if (state.canManagePlan && state.isInSchoolPlan) {
      state.schoolPlanOpenId = state.managePlanOpenId;
    }
    state.Plans = plans || [];

    state.PasswordInsecure = param["PasswordInsecure"];

    state.canReadComic = state.notStudent || state.age >= 0;
    state.canReadPictureStory = state.notStudent || state.age >= 0;
    state.canReadFlashFiction = state.notStudent || state.age >= 0;
    state.canReadLongformStory = state.notStudent || state.age >= 0;
    state.canReadPoetry = state.notStudent || state.age >= 0;
  },

  RESET_USER_INFO: (state) => {
    state.isLogin = false;
    state.username = "";
    state.avatar = "";
    state.email = "";
    state.lastLogin = "";
    state.dateJoined = "";
    state.accountType = "";
    state.originalAvatar = "";
    state.stripeCustomerId = "";
    state.largeAvatar = "";
    state.isMember = false;
    state.crown = 0;
    state.gift = 0;
    state.isStudent = false;
    state.isClassStudent = false;
    state.notStudent = false;
    state.isTeacher = false;
    state.isArtist = false;
    state.ticket = 0;
    state.birthday = "";
    state.age = 0;
    state.ageGroup = 0;
    state.uid = 0;
    state.jwt = null;
    state.level = 0;
    state.theme = "";
    state.challengeId = 0;
    state.isActive = false;
    state.joinFromPreviewSite = false;
    state.searchKey = "";
    state.canManagePlan = false;
    state.managePlanOpenId = "";
    state.isInSchoolPlan = false;
    state.isInFamilyPlan = false;
    state.isNewBorn = false;
    state.familyPlanExpired = true;
    state.schoolPlanOpenId = "";
    state.familyPlanOpenId = "";
    state.barClosed = getExpireBarClose() == "1";
    state.bookCount = 0;
    state.isInFreeTrial = false;
    state.Plans = [];
    state.PasswordInsecure = false;
    state.FreeTrialExpireTime = 0;
    state.MembershipTime = 0;
    state.Country = "";

    // 其他属性
    state.role = "";
    state.canReadComic = false;
    state.canReadPictureStory = false;
    state.canReadFlashFiction = false;
    state.canReadLongformStory = false;
    state.canReadPoetry = false;

    state.canReadOtherBook = true; // 允许浏览阅读模块
    state.canPublicBook = true; // 允许公开发布书本
  },

  // 保存用户写书总数
  SET_WRITE_BOOK_COUNT: (state, count) => {
    state.bookCount = count;
  },

  UPDATE_EXPIRE_BAR_CLOSE: (state, val) => {
    state.barClosed = val == 1;
  },

  // 设置班级学生权限
  SET_STUDENT_PEIMISSION: (state, params = {}) => {
    if ("ReadOtherBook" in params) {
      state.canReadOtherBook = params.ReadOtherBook;
    }
    if ("PublicBook" in params) {
      state.canPublicBook = params.PublicBook;
    }
  },
};

const actions = {
  // 获取用户详细信息
  loadUserInfo({ commit, dispatch }, params = {}) {
    return new Promise((resolve, reject) => {
      userInfo()
        .then(async (res) => {
          if (res.code === 0) {
            const userInfo = res.result.info;
            //加一个拦截：如果用户被锁住，那就退出登录
            if (userInfo && userInfo.IsActive == "2") {
              dispatch("logout");
              reject(res);
              return;
            }
            commit("SET_USER_INFO", userInfo);
            dispatch("loadBookCount");
            const { tag } = params;
            if (tag === "init" || tag === "login") {
              // 首次初始化页面
              dispatch("plan/updatePlan", { forceUpdate: true }, { root: true });
              dispatch("basket/loadBasketList", {}, { root: true });
              try {
                await dispatch("class/loadClassList", { forceUpdate: true }, { root: true });
              } catch (error) {
                //
              }
            }
            applyTheme(userInfo.Theme);
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // 获取用户写书的总数
  loadBookCount({ commit }) {
    getBookCount().then((res) => {
      if (res.code === 0) {
        commit("SET_WRITE_BOOK_COUNT", res.result.count);
      } else {
        commit("SET_WRITE_BOOK_COUNT", 0);
      }
    });
  },

  // 退出登录
  logout({ commit, dispatch }, nextPage) {
    setToken(null);
    setExpireBarClose("0");
    deleteCookie("SharePassword");
    setCurrentClassOpenId(null);
    commit("RESET_USER_INFO");
    // 其他数据也要重置
    dispatch("basket/clear", {}, { root: true });
    dispatch("notification/reset", {}, { root: true });
    dispatch("plan/clear", {}, { root: true });
    dispatch("class/reset", {}, { root: true });
    dispatch("awstoken/resetToken", {}, { root: true });
    if (nextPage && nextPage.length > 0) {
      router.push(nextPage);
    } else {
      router.replace("/signin");
    }
  },

  // 更新用户cookie
  updateUserCookie({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      UpdateInfo({})
        .then(async (res) => {
          if (params && params.reload) {
            const userinfo = await dispatch("loadUserInfo");
            if (userinfo && userinfo.code == 0) {
              resolve(userinfo);
            } else {
              reject(userinfo);
            }
          } else {
            dispatch("loadUserInfo");
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateExpireBarClose({ commit }, val) {
    commit("UPDATE_EXPIRE_BAR_CLOSE", val);
  },

  reset({ commit }) {
    commit("RESET_USER_INFO");
  },

  // 设置班级学生权限
  setStudentPermission({ commit }, params = {}) {
    commit("SET_STUDENT_PEIMISSION", params);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
