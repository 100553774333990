// 文言国际化
// import Vuei18n from "vue-i18n";
import zh from "./lang/zh";
import en from "./lang/en";
import latin from "./lang/latin";
// Vue.use(Vuei18n);

//获取缓存语言
let lang = localStorage.getItem("stbLang");
if (!lang) {
  if (location.href && location.href && location.href.indexOf("magikid") > -1) {
    // 国内
    lang = "zh";
  } else {
    if (new Date().getTimezoneOffset() / 60 == -8) {
      lang = "zh";
    } else {
      lang = "en";
    }
  }
  if (!lang) {
    lang = "en";
  }
  localStorage.setItem("stbLang", lang);
}

// npm时使用Vuei18n
const i18n = new VueI18n({
  locale: lang, // 语言标识
  messages: {
    zh: zh, // 中文语言包
    en: en, // 英文语言包
    latin: latin, // 拉丁语
  },
});
export default i18n;
