import request from "@/utils/request";
// import qs from "qs";

export function initialData(path) {
  return request({
    url: `/api/Index/InitialData?request_uri=${encodeURIComponent(path)}`,
    method: "get",
    // data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function CarouselShare(path) {
  return request({
    url: `/api/Statistics/CarouselShare?request_uri=${encodeURIComponent(path)}`,
    method: "get",
    // data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}
