// $message.success
export function showSuccessMsg(msg) {
  ELEMENT &&
    ELEMENT.Message &&
    ELEMENT.Message({
      type: "success",
      message: msg,
      offset: 80,
      customClass: "tmg-element-message",
    });
}

// $message.error
export function showErrorMsg(msg) {
  ELEMENT &&
    ELEMENT.Message &&
    ELEMENT.Message({
      type: "error",
      message: msg,
      offset: 80,
      customClass: "tmg-element-message",
    });
}

// $alert
export function showAlert(message, title, options = {}) {
  ELEMENT &&
    ELEMENT.MessageBox &&
    ELEMENT.MessageBox.alert(message, title, {
      confirmButtonClass: "alert-btn-conform-blue",
      zIndex: 20000,
      ...options,
    });
}

// 显示危险弹框
export function showDangerAlert(message, title, options = {}) {
  const ops = {
    cancelButtonClass: "alert-btn-cancel",
    confirmButtonClass: "alert-btn-conform-danger",
    ...options,
  };
  ELEMENT && ELEMENT.MessageBox && ELEMENT.MessageBox.alert(message, title, ops);
}

// 显示确认弹框
export function showConfirmDialg(message, title, options = {}) {
  const ops = {
    // cancelButtonClass: "alert-btn-cancel",
    confirmButtonClass: "alert-btn-conform-blue",
    showCancelButton: true,
    ...options,
  };
  ELEMENT && ELEMENT.MessageBox && ELEMENT.MessageBox.alert(message, title, ops);
}

// 全局Loading
export function showLoading(options) {
  let loadingInstance = null;
  if (ELEMENT.Loading) {
    loadingInstance = ELEMENT.Loading.service(options);
  }
  return loadingInstance;
}
