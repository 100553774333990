<template>
  <div class="check-block-layer">
    <!-- safari弹窗 -->
    <div class="safari-wrap" v-if="safariUrl" style="background-color: rgba(0, 0, 0, 0.7); z-index: 999999999">
      <div class="safari-inner" style="flex-direction: column">
        <div style="text-align: center">
          {{ $t("members.shop.explorerBlocked") }}
        </div>
        <div class="btn btn-blue" style="margin-top: 10px" @click="openPaymentUrlClick">
          {{ $t("members.shop.ContinuePayment") }}
        </div>
      </div>
    </div>

    <!-- 图 -->
    <div class="checkout-form">
      <div class="top-img">
        <img :src="productImage" class="product-image" />
      </div>
      <div class="checkout-inner">
        <!-- 如果不需要输入人数，则左右排列 -->

        <!-- 添加学校会员人数 -->
        <div v-if="payItem === 'PLAN_ADD_MEMBER'">
          <div class="product-name">{{ productName }}</div>
          <div class="price" style="width: 91px; float: right">
            <span>$</span>
            {{ toThousands(originalPrice) }}
          </div>
        </div>

        <!-- 学校会员 -->
        <div v-else-if="isSchoolPlanItem">
          <div class="product-name" style="width: 100%; margin-bottom: 5px">
            {{ productName }}
            <span style="font-weight: 400">/One Year</span>
          </div>

          <input
            @blur="tips2 = 'Number of members'"
            @focus="tips2 = ''"
            :placeholder="tips2"
            class="coupon-code"
            v-model="numberOfMembers"
            :disabled="memberCount"
            :style="{
              'box-shadow': byTips2,
              'border-color': checkNum(numberOfMembers) ? '' : '#FF9592',
            }"
          />
          <div class="members-account">Members</div>
          <div
            v-if="checkNum(numberOfMembers) && false"
            class="price"
            style="margin-top: 6px; font-size: 13px; color: #000"
          >
            <span>$</span>
            {{ toThousands(originalPrice) }}
          </div>
          <div
            v-if="!checkNum(numberOfMembers) && false"
            class="price"
            style="margin-top: 6px; font-size: 13px; color: #000"
          >
            <span>$</span>
            ----
          </div>
          <div v-if="!checkNum(numberOfMembers)" :class="{ tips: 1, 'tips-red': !checkNum(numberOfMembers) }">
            {{
              payItem == "FAMILY_PLAN"
                ? "Please enter a number between 2-5"
                : minSchoolPlanNum > 200
                ? "You have more than 200 students in your classes, please try District Plan"
                : "Please enter a number between " + minSchoolPlanNum + "-200"
            }}
          </div>
          <div
            v-if="isSchoolPlanItem && numberOfMembers > 200"
            style="font-size: 12px; margin-top: 5px; line-height: 15px"
          >
            <a @click="guide2District">For over 200 members, please contact us for District Plan</a>
          </div>

          <!-- 学校套餐计算表 -->
          <div v-if="isSchoolPlanItem" style="margin-top: 10px">
            <div class="price-tips">Enter total number of students and teachers.</div>
          </div>

          <div v-if="checkNum(numberOfMembers) && false" class="price-tips">
            *Include teachers and students, average
            <span style="color: #393232">
              <span>$</span>
              {{ toThousands((numberOfMembers > 0 ? originalPrice / numberOfMembers : 0).toFixed(2)) }}
            </span>
            per member per year.
          </div>
        </div>

        <!-- 家庭会员 -->
        <div v-else-if="payItem == 'FAMILY_PLAN'">
          <div class="product-name" style="width: 100%; margin-bottom: 5px">
            {{ productName }}
            <span style="font-weight: 400">/One Year</span>
          </div>

          <div class="family-price-wrap">
            <div class="family-price-text">1 Parent Account + 5 Children Accounts</div>
            <!-- <div class="family-price-text">1 Parent Account</div>
            <div class="family-price-text-free">(free)</div>
            <div class="family-price-text-plus">+</div>
            <input
              @blur="tips2 = 'Number of members'"
              @focus="tips2 = ''"
              :placeholder="tips2"
              class="coupon-code"
              disabled
              v-model="numberOfMembers"
              :style="{
                width: '60px',
                'margin-right': '4px',
                'box-shadow': byTips2,
                'border-color': checkNum(numberOfMembers) ? '' : '#FF9592',
              }"
            />
            <div class="family-price-text">Children Accounts</div> -->
          </div>

          <div
            v-if="checkNum(numberOfMembers) && false"
            class="price"
            style="margin-top: 6px; font-size: 13px; color: #000"
          >
            <span>$</span>
            {{ toThousands(originalPrice) }}
          </div>
          <div v-if="!checkNum(numberOfMembers)" :class="{ tips: 1, 'tips-red': !checkNum(numberOfMembers) }">
            "Please enter a number between 2-5"
          </div>

          <!-- 家庭套餐计算表：家庭套餐已指定人数为5 -->
          <div v-if="false && checkNum(numberOfMembers)" style="margin-top: 10px">
            <div class="price-tips">
              2 children
              <span>$</span>
              {{ priceForFamily1 }} (30% off) &times; 2
              <div class="right-price">
                <span>$</span>
                {{ (priceForFamily1 * 2).toFixed(2) }}
              </div>
            </div>
            <div class="price-tips" v-if="numberOfMembers > 2">
              From 3 to 5 children
              <span>$</span>
              {{ priceForFamily2 }} (40% off) &times;
              {{ numberOfMembers - 2 }}
              <div class="right-price">
                <span>$</span>
                {{ (priceForFamily2 * (numberOfMembers - 2)).toFixed(2) }}
              </div>
            </div>
          </div>
        </div>

        <!-- 购买其他项目 -->
        <div v-else>
          <div class="product-name">{{ productName }}</div>
          <div class="price" style="width: 91px; float: right">
            <span>$</span>
            {{ toThousands(originalPrice) }}
          </div>
        </div>
        <div class="checkout-line"></div>

        <!-- 优惠券：月会员不能使用优惠券 -->
        <div v-if="!isMonthPlan && checkNum(numberOfMembers)">
          <input
            @blur="tips1 = 'Coupon Code(Optional)'"
            @keyup="couponnotice.IsRight = true"
            @focus="tips1 = ''"
            @keyup.enter="checkCoupon"
            :placeholder="tips1"
            class="coupon-code"
            type="text"
            v-model="couponCode"
            :style="{
              'box-shadow': byTips1,
              'border-color': couponnotice.IsRight ? '' : '#FF9592',
            }"
          />
          <div
            @click="checkCoupon"
            :class="{
              'coupon-apply': 1,
              'coupon-apply-grey': couponCode.trim().length <= 0,
            }"
          >
            {{ btnName }}
          </div>
          <div
            v-if="couponMinus > 0"
            class="right-price"
            style="color: #ff6666; font-size: 13px; line-height: 20px; margin-top: 3px"
          >
            -
            <span>$</span>
            {{ toThousands(couponMinus) }}
          </div>
          <div v-if="couponnotice.IsShow" class="price-tips">
            <span class="tips-red" v-if="couponnotice.text != ''" v-html="couponnotice.text"></span>
          </div>
          <div
            v-if="couponMinus > 0 && false"
            class="price"
            style="margin-top: 6px; color: #ff6666; font-size: 13px; float: right"
          >
            -
            <span>$</span>
            {{ toThousands(couponMinus) }}
          </div>
          <div class="checkout-line"></div>
        </div>

        <!-- 会员折价 -->
        <div
          v-if="(payItem == 'FAMILY_PLAN' || isSchoolPlanItem) && membershipDiscount > 0 && checkNum(numberOfMembers)"
        >
          <div
            style="display: inline-block; vertical-top; line-height: 15px; width: 195px; color: #666; font-size: 12px;"
          >
            Your existing membership balance deduction:
          </div>
          <div class="price" style="margin-top: 6px; color: #ff6666; font-size: 13px; float: right">
            -
            <span>$</span>
            {{ toThousands(membershipDiscount) }}
          </div>
          <div class="checkout-line"></div>
        </div>

        <!-- 总价格 -->
        <div v-if="checkNum(numberOfMembers)" class="total-price">
          Total:
          <span>$</span>
          {{ toThousands(finalPrice) }} (￥{{ toThousands((finalPrice * rate).toFixed(2)) }})
        </div>
        <div v-else class="total-price">Total: ----</div>
        <div
          v-if="regularPrice > finalPrice && checkNum(numberOfMembers) && false"
          style="text-align: right; font-size: 12px; color: #a7a6a6; margin-top: 2px"
        >
          You've saved:
          <span style="color: #ff6666">
            <span>$</span>
            {{ toThousands((regularPrice - finalPrice).toFixed(2)) }}
          </span>
        </div>

        <!-- 选择支付方式 -->
        <div class="choose-pay-mode-wrap">
          <div class="title">
            {{ $t("members.shop.paymentMethod") }}
          </div>

          <!-- Stripe -->
          <div class="options" :style="{ pointerEvents: isPaying ? 'none' : 'auto' }">
            <div class="option mt5" @click="onCreditCardPayClick()">
              <img class="choose-pay-img" src="/assets/images/pay/new/creditcard.png" />
              Credit Card
            </div>

            <!-- Paypal -->
            <div
              class="option mt5"
              style="padding-left: 0px; border: none; width: 155px"
              id="paypal-button-container"
            ></div>

            <!-- PO Pay -->
            <div v-if="enablePoPay" class="option mt10" @click="onPoPayClick">
              <img class="choose-pay-img" src="/assets/images/pay/new/check.png" />
              PO Pay
            </div>
          </div>
        </div>

        <!-- 操作按钮 -->
        <!-- <div class="btn btn-green order-button" style="width: 150px">Place Your Order</div> -->
        <div v-if="!isPaying" class="btn cancel-button" @click="close">Cancel</div>
        <div v-else>
          <spinner top="15" text="Checking the payment result, please wait"></spinner>
        </div>

        <!-- 产品简介或者提示 -->
        <div class="product-desc">{{ description }}</div>

        <div v-if="!User.isTeacher" class="order-notice">
          By clicking Place Your Order you acknowledge that you are 18 years or older or have permission from your
          parent or legal guardian to purchase and that the terms of Storybird's Refund Policy will govern your
          purchase. All sales are final.
        </div>
        <div v-else class="order-notice">
          By clicking Place Your Order you acknowledge that you are 18 years or older and that the terms of Storybird's
          Refund Policy will govern your purchase. All sales are final.
        </div>
      </div>
    </div>

    <el-dialog title="" :visible.sync="poPayDialogVisible" width="650px" top="30vh" :modal-append-to-body="false">
      <div>
        <div style="line-height: 24px; margin-bottom: 20px">
          You are using PO payment, please complete the form information
        </div>
        <el-form :model="poForm" label-width="110px" inline>
          <el-form-item label="Item">
            <el-input v-model="poForm.Item" disabled></el-input>
          </el-form-item>
          <el-form-item label="Members">
            <el-input v-model="poForm.members" disabled></el-input>
          </el-form-item>
          <el-form-item label="UserName" prop="InvoiceUserName" required error="required">
            <el-input v-model="poForm.InvoiceUserName" placeholder="full name"></el-input>
          </el-form-item>
          <el-form-item label="SchoolName" prop="InvoiceSchoolName" required error="required">
            <el-input v-model="poForm.InvoiceSchoolName" placeholder="school name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="button-standard" @click="poPayDialogVisible = false">Cancel</el-button>
        <el-button class="button-standard button-blue" type="primary" @click="onPoPayContinueClick">Continue</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";
import {
  GetExchangeRate,
  CheckCoupon,
  Pay,
  GetMembershipDiscount,
  GetMinSchoolPlanNum,
  MakePaySession,
} from "@/api/shop.js";
import { push } from "@/utils/RouterManager.js";
import { showAlert } from "@/utils/element-ui/message.js";
import { getSchoolPriceByNumbers, getFamilyPriceByNumbers } from "@/utils/price.js";
import { CapturePaypalOrder, CheckPaypalStatus } from "@/api/paypal.js";
import { showErrorMsg } from "@/utils/element-ui/message.js";
export default {
  props: [
    "productImage",
    "productName",
    "description",
    "price",
    "payItem",
    "payPostData",
    "memberCount",
    "invitationCode",
    "OpenId",
  ],
  data() {
    return {
      handler: null,
      tokenId: "",
      isPaying: false, // 是否正在支付
      numberOfMembers: 1,
      membershipDiscount: 0,
      couponCode: "",
      couponCodeFixed: "",
      couponMinus: 0,
      safariUrl: "",
      minSchoolPlanNum: 10,
      priceForFamily1: 41.83,
      priceForFamily2: 19.93,
      rate: 1.0,
      btnName: "Apply",
      tips1: "Coupon Code(Optional)",
      tips2: "Number of members",
      couponnotice: {
        IsShow: false,
        IsRight: true,
        text: "",
      },
      paypalMonthPlanOrderNo: null, // Paypal月会员订阅订单号
      poPayDialogVisible: false,
      poForm: {
        Item: "",
        members: 0,
        InvoiceUserName: null,
        InvoiceSchoolName: null,
      },
    };
  },
  computed: {
    User() {
      return this.$store.getters.User;
    },
    regularPrice() {
      return this.price * this.numberOfMembers;
    },
    originalPrice() {
      let tp = this.price;

      if (this.checkNum(this.numberOfMembers)) {
        if (this.payItem == "FAMILY_PLAN") {
          // 家庭套餐
          tp = getFamilyPriceByNumbers(this.numberOfMembers);
        } else if (this.isSchoolPlanItem) {
          // 学校套餐
          tp = getSchoolPriceByNumbers(this.numberOfMembers);
        }
      } else {
        // 输入的人数有问题
        tp = 0;
      }

      return (tp * 1).toFixed(2);
    },
    beforeCouponPrice() {
      return (this.originalPrice - this.membershipDiscount).toFixed(2);
    },
    finalPrice() {
      return (this.beforeCouponPrice - this.couponMinus).toFixed(2);
    },
    byTips2() {
      if (this.tips2) {
        return "";
      } else {
        return "1px 1px 1px 0px #ccc inset";
      }
    },
    byTips1() {
      if (this.tips1) {
        return "";
      } else {
        return "1px 1px 1px 0px #ccc inset";
      }
    },
    isMonthPlan() {
      return this.payItem === "MONTHLY_PLAN";
    },

    isYearlyPlan() {
      return this.payItem === "ONE_YEAR";
    },

    // 购买的是否是学校计划
    isSchoolPlanItem() {
      if (this.payItem === "SCHOLL_PLAN" || this.payItem === "RENEWAL_SCHOLL_PLAN") {
        return true;
      }
      return false;
    },

    // 是否是购买打印book
    isPrintBookItem() {
      return this.payItem === "PRINT_BOOK";
    },

    // 是否允许使用po 支付
    enablePoPay() {
      if (this.isSchoolPlanItem) {
        return true;
      }
      return false;
    },
  },
  watch: {
    numberOfMembers(val) {
      this.checkNum(val);
      this.clearCoupon();
    },
  },

  created() {
    if (this.payItem == "FAMILY_PLAN") {
      this.numberOfMembers = 5;
      // this.getMembershipDiscount();
    } else if (this.isSchoolPlanItem) {
      this.numberOfMembers = this.memberCount ? this.memberCount : this.minSchoolPlanNum;
      // this.getMembershipDiscount();
      this.getMinSchoolPlanNum();
    } else if (this.payItem == "PLAN_ADD_MEMBER") {
      // 添加学校会员人数
      this.numberOfMembers = this.memberCount;
    }
    this.getExchangeRate();
    if (this.isMonthPlan) {
      this.loadPaypalSubscriptionScripe(process.env.VUE_APP_PAYPAL_SUBSCRIPTION_PLAN_ID);
    } else if (this.isYearlyPlan) {
      this.loadPaypalSubscriptionScripe(process.env.VUE_APP_PAYPAL_SUBSCRIPTION_YEAR_PLAN_ID);
    } else {
      this.loadPaypalScripe();
    }
  },

  methods: {
    getExchangeRate() {
      GetExchangeRate()
        .then((re) => {
          this.rate = re.result;
        })
        .catch(() => {});
    },

    goPage(msg) {
      push(this, msg);
    },

    onCreditCardPayClick(payChannal, otherParams) {
      if (this.isPaying) {
        // 正在支付
        return;
      }

      if (this.payItem == "FAMILY_PLAN") {
        if (!this.checkNum(this.numberOfMembers)) {
          showAlert("Family plan requires at least two members to participate and up to five participants.");
          return;
        }
      } else if (this.isSchoolPlanItem) {
        if (!this.checkNum(this.numberOfMembers)) {
          showAlert(
            "You have added " +
              this.minSchoolPlanNum +
              " teachers and students in your class. Please enter a number " +
              this.minSchoolPlanNum +
              "-200 to cover all the current accounts.",
          );
          return;
        }
      }

      if (this.finalPrice <= 0) {
        showAlert("Your order total price must be greater than 0.");
        return;
      }

      this.isPaying = true;
      let payPData = {
        PayItem: this.payItem,
        Ammount: (this.numberOfMembers + "").trim(),
        Coupon: this.couponCodeFixed,
        successUrl: window.origin + "/account-orders",
        callbackUrl: window.origin + "/pay-callback",
        cancelUrl: window.origin + "/pay-cancel",
        PayChannel: 0,
      };
      if (payChannal) {
        payPData["PayChannel"] = payChannal;
      }

      if (this.payPostData) {
        for (let k in this.payPostData) {
          payPData[k] = this.payPostData[k];
        }
      }

      if (otherParams) {
        for (let k in otherParams) {
          payPData[k] = otherParams[k];
        }
      }

      if (this.invitationCode && this.invitationCode.length > 0) {
        // 只有school Plan有邀请码
        payPData["invitationCode"] = this.invitationCode;
      }

      const LogParams = {
        type: payPData.PayItem,
        amount: payPData.Ammount,
        channel: payPData.PayChannel,
      };
      this.$logEvent.trace(this.$logEvent.EventKey.USER_PAY, LogParams);

      MakePaySession(payPData)
        .then((re) => {
          this.isPaying = false;
          if (re.code == 0) {
            if (payChannal == 9) {
              this.handlePoPayResult(re);
            } else {
              localStorage.setItem("PayStatus", "checking");
              this.listenPayStatus();
              let w = window.open("/redirect-stripe?id=" + re.result);
              if (w == null) {
                this.safariUrl = "/redirect-stripe?id=" + re.result;
              }
            }
          } else {
            showAlert(this.$t("members.shop.PayFail"));
          }
        })
        .catch((err) => {
          this.isPaying = false;
          if (err && err.msg) {
            showAlert(err.msg);
          } else {
            showAlert(this.$t("members.shop.PayFail"));
          }
        });
    },

    checkCoupon() {
      if (this.beforeCouponPrice > 0) {
        if ((this.couponCode + "").trim().length > 0) {
          this.btnName = "Apply...";
          let that = this;

          // 续订学校计划时，参数统一改为 SCHOLL_PLAN
          const type = this.isSchoolPlanItem ? "SCHOLL_PLAN" : this.payItem;

          CheckCoupon({
            Code: this.couponCode,
            CurrentPrice: this.beforeCouponPrice,
            ItemType: type,
          })
            .then((res) => {
              that.btnName = "Apply";
              that.couponnotice.IsShow = true;
              that.couponnotice.text = "";
              that.couponnotice.IsRight = true;
              that.couponMinus = res.result.ammout.toFixed(2);
              that.couponCodeFixed = res.result.code;
            })
            .catch((res) => {
              that.btnName = "Apply";
              that.couponnotice.IsShow = true;
              that.couponnotice.text = "";
              that.clearCoupon();
              that.couponnotice.IsRight = false;
              that.couponnotice.text = res.msg;
            });
        } else {
          // 清空coupon
          this.clearCoupon();
        }
      } else {
        // 总价为0
        this.clearCoupon();
        showAlert("Your total price should be greater than 0.");
      }
    },
    checkNum(val) {
      val = (val + "").trim();
      let re = true;
      if (this.payItem == "FAMILY_PLAN") {
        if (this.isPositiveInteger(val)) {
          // 是整数
          if (Number(val) > 5 || Number(val) < 2) {
            re = false;
          }
        } else {
          re = false;
        }
      } else if (this.isSchoolPlanItem) {
        if (this.isPositiveInteger(val)) {
          // 是整数
          if (Number(val) > 200 || Number(val) < this.minSchoolPlanNum) {
            re = false;
          }
        } else {
          re = false;
        }
      }
      return re;
    },
    isPositiveInteger(s) {
      let re = /^[0-9]+$/;
      return re.test(s);
    },
    //手动打开付款链接
    openPaymentUrlClick() {
      // safariUrl = "/redirect-stripe?id=" + re.result;
      window.open(this.safariUrl);
      this.safariUrl = "";
    },

    handlePayStatus(e) {
      let t = this;
      if (e.key && e.key == "PayStatus" && e.newValue) {
        if (e.newValue == "success") {
          this.$store.dispatch("user/updateUserCookie");
          showAlert(this.$t("members.shop.Thank"), "", {
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            callback: (action) => {
              if (action === "confirm") {
                try {
                  t.close();
                } catch (e) {
                  console.log(e);
                }
                if (window.stripeCallback) {
                  window.stripeCallback();
                }
              }
            },
          });
        }

        if (e.newValue == "fail") {
          t.close();
          showAlert("Pay failed, please try again.");
        }

        if (e.newValue == "cancel") {
          t.close();
        }
      }
    },
    unListenPayStatus() {
      window.removeEventListener("storage", this.handlePayStatus);
    },
    listenPayStatus() {
      window.removeEventListener("storage", this.handlePayStatus);
      window.addEventListener("storage", this.handlePayStatus);
    },
    payCall() {
      let t = this;
      t.isPaying = true;

      let payPData = {
        StripeToken: t.tokenId,
        PayItem: t.payItem,
        Ammount: (t.numberOfMembers + "").trim(),
        Coupon: t.couponCodeFixed,
      };

      if (this.payPostData) {
        for (let k in this.payPostData) {
          payPData[k] = this.payPostData[k];
        }
      }

      Pay(payPData)
        .then((res) => {
          t.isPaying = false;
          if (res.code == 0) {
            this.$store.dispatch("user/updateUserCookie");
            showAlert(this.$t("members.shop.Thank"), "", {
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              callback: (action) => {
                if (action === "confirm") {
                  try {
                    t.close();
                  } catch (e) {
                    console.log(e);
                  }
                  if (window.stripeCallback) {
                    window.stripeCallback();
                  }
                }
              },
            });
          } else if (res.code == 1000) {
            showAlert(this.$t("members.shop.PayFail") + " " + res.result);
          } else {
            showAlert(this.$t("members.shop.PayFail"));
          }
        })
        .catch(() => {});
    },
    close() {
      this.unListenPayStatus();
      this.$el.parentNode.removeChild(this.$el);
      this.$destroy();
    },
    getMembershipDiscount() {
      GetMembershipDiscount({
        Type: this.payItem,
        UnitPrice: this.price,
      })
        .then((res) => {
          if (res.code == 0 && res.result["IsNewer"]) {
            this.membershipDiscount = res.result["ExchangePrice"];
            this.clearCoupon();
          }
        })
        .catch(() => {});
    },
    getMinSchoolPlanNum() {
      // 如果是开通学校套餐，查看最少需要多少人
      GetMinSchoolPlanNum()
        .then((res) => {
          this.minSchoolPlanNum = res.result > this.minSchoolPlanNum ? res.result : this.minSchoolPlanNum;
          this.numberOfMembers =
            this.numberOfMembers >= this.minSchoolPlanNum ? this.numberOfMembers : this.minSchoolPlanNum;
        })
        .catch(() => {});
    },
    guide2District() {
      try {
        this.close();
      } catch (e) {
        console.log(e);
      }
      this.goPage("/become-member?showdistrict=1");
    },
    toThousands(val) {
      // 分离小数和整数
      let num = parseInt(val);
      let rightPart = (val + "").split(".")[1];

      return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "." + (rightPart ? rightPart : "00");
    },
    clearCoupon() {
      this.couponCodeFixed = "";
      this.couponCode = "";
      this.couponMinus = 0;
    },

    // 获取Paypal支付状态，确认是否支付成功（订阅月会员）
    checkPaypalStatus(subscriptionID, orderID) {
      const that = this;
      const params = {
        id: subscriptionID,
        orderID: orderID,
      };
      CheckPaypalStatus(params)
        .then((res) => {
          if (res && res.code === 0) {
            this.isPaying = false;
            this.$store.dispatch("user/updateUserCookie");
            showAlert(this.$t("members.shop.Thank"), "", {
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              callback: (action) => {
                if (action === "confirm") {
                  that.close();
                  if (window.stripeCallback) {
                    window.stripeCallback();
                  }
                }
              },
            });
          } else {
            setTimeout(() => {
              that.checkPaypalStatus(subscriptionID, orderID);
            }, 1500);
          }
        })
        .catch(() => {
          setTimeout(() => {
            that.checkPaypalStatus(subscriptionID, orderID);
          }, 1500);
        });
    },

    // 加载Paypal支付脚本（普通支付）
    loadPaypalScripe() {
      const that = this;
      loadScript({ clientId: process.env.VUE_APP_PAYPAL_CLIENT_ID, components: "buttons,funding-eligibility" })
        .then((paypal) => {
          const button_config = {
            fundingSource: paypal.FUNDING.PAYPAL,
            style: {
              layout: "vertical",
              color: "silver",
              shape: "rect",
              // label: "buynow",
              disableMinWidth: true,
              disableMaxWidth: true,
              height: 32,
            },
            createOrder() {
              if (that.isPaying) {
                // 正在支付
                return null;
              }

              // Order is created on the server and the order id is returned
              if (that.payItem == "FAMILY_PLAN") {
                if (!that.checkNum(that.numberOfMembers)) {
                  showAlert("Family plan requires at least two members to participate and up to five participants.");
                  return;
                }
              } else if (that.isSchoolPlanItem) {
                if (!that.checkNum(that.numberOfMembers)) {
                  showAlert(
                    "You have added " +
                      that.minSchoolPlanNum +
                      " teachers and students in your class. Please enter a number " +
                      that.minSchoolPlanNum +
                      "-200 to cover all the current accounts.",
                  );
                  return;
                }
              }

              if (that.finalPrice <= 0) {
                showAlert("Your order total price must be greater than 0.");
                return;
              }

              let payPData = {
                PayChannel: 8, // Paypal 类型支付
                PayItem: that.payItem,
                Ammount: (that.numberOfMembers + "").trim(),
                Coupon: that.couponCodeFixed,
                successUrl: window.origin + "/account-orders",
                callbackUrl: window.origin + "/pay-callback",
                cancelUrl: window.origin + "/pay-cancel",
              };

              if (that.payPostData) {
                for (let k in that.payPostData) {
                  payPData[k] = that.payPostData[k];
                }
              }
              if (that.invitationCode && that.invitationCode.length > 0) {
                // 只有school Plan有邀请码
                payPData["invitationCode"] = that.invitationCode;
              }

              that.isPaying = true;

              const LogParams = {
                type: payPData.PayItem,
                amount: payPData.Ammount,
                channel: payPData.PayChannel,
              };
              that.$logEvent.trace(that.$logEvent.EventKey.USER_PAY, LogParams);

              return MakePaySession(payPData)
                .then((res) => {
                  return res.result;
                })
                .then((order) => {
                  return order.id;
                });
            },
            onApprove(data) {
              // This function captures the funds from the transaction.
              const params = {
                id: data.orderID,
              };
              CapturePaypalOrder(params)
                .then((res) => {
                  if (res && res.code === 0) {
                    that.isPaying = false;
                    that.$store.dispatch("user/updateUserCookie");
                    showAlert(that.$t("members.shop.Thank"), "", {
                      showClose: false,
                      closeOnClickModal: false,
                      closeOnPressEscape: false,
                      callback: (action) => {
                        if (action === "confirm") {
                          that.close();
                          if (window.stripeCallback) {
                            window.stripeCallback();
                          }
                        }
                      },
                    });
                  } else {
                    showErrorMsg(res.msg);
                  }
                })
                .catch((err) => {
                  that.isPaying = false;
                  err && err.msg && showErrorMsg(err.msg);
                });
            },

            onCancel() {
              // Show a cancel page, or return to cart
              // 用户取消支付
              that.isPaying = false;
            },

            onError() {
              // 支付异常
              that.isPaying = false;
              // For example, redirect to a specific error page
              // window.location.href = "/your-error-page-here";
            },
          };
          let button = paypal.Buttons(button_config);
          if (button.isEligible()) {
            button.render("#paypal-button-container");
          }
        })
        .catch((error) => {
          console.error("failed to load the PayPal JS SDK script", error);
        });
    },

    // 加载Paypal支付脚本（订阅支付）
    loadPaypalSubscriptionScripe(subscriptionPlanId) {
      const that = this;
      loadScript({
        clientId: process.env.VUE_APP_PAYPAL_CLIENT_ID,
        components: "buttons,funding-eligibility",
        vault: true,
        intent: "subscription",
      })
        .then((paypal) => {
          const button_config = {
            fundingSource: paypal.FUNDING.PAYPAL,
            style: {
              layout: "vertical",
              color: "silver",
              shape: "rect",
              // label: "buynow",
              disableMinWidth: true,
              disableMaxWidth: true,
              height: 32,
            },

            createSubscription(data, actions) {
              return actions.subscription.create({
                plan_id: subscriptionPlanId,
                custom_id: that.User.uid,
              });
            },

            onApprove(info) {
              this.isPaying = true;
              // 用户授权订阅，去后台确认订阅状态
              that.checkPaypalStatus(info.subscriptionID, info.orderID);
            },

            onCancel() {
              // Show a cancel page, or return to cart
              // 用户取消支付
              that.isPaying = false;
            },

            onError(err) {
              // 支付异常
              that.isPaying = false;
              if (err && err.msg) {
                showErrorMsg(err.msg);
              }
            },
          };
          let button = paypal.Buttons(button_config);
          if (button.isEligible()) {
            button.render("#paypal-button-container");
          }
        })
        .catch((error) => {
          console.error("failed to load the PayPal JS SDK script", error);
        });
    },

    onPoPayClick() {
      if (!this.enablePoPay) {
        showAlert("The Payment methods are not supported");
        return;
      }
      this.poForm = {
        Item: "",
        members: this.numberOfMembers,
        InvoiceUserName: null,
        InvoiceSchoolName: null,
      };
      if (this.payItem === "SCHOLL_PLAN") {
        this.poForm.Item = "Storybird School Plan";
      } else if (this.payItem === "RENEWAL_SCHOLL_PLAN") {
        this.poForm.Item = "Renew Storybird School Plan";
      }
      this.poPayDialogVisible = true;
    },

    // 确认使用PO支付
    onPoPayContinueClick() {
      if (!this.enablePoPay) {
        showAlert("The Payment methods are not supported");
        return;
      }
      if (!this.poForm.InvoiceUserName || this.poForm.InvoiceUserName.length === 0) {
        return;
      }
      if (!this.poForm.InvoiceSchoolName || this.poForm.InvoiceSchoolName.length === 0) {
        return;
      }
      this.poPayDialogVisible = false;
      const otherParams = {
        fullName: this.poForm.InvoiceUserName,
        schoolName: this.poForm.InvoiceSchoolName,
      };
      this.onCreditCardPayClick(9, otherParams);
    },

    handlePoPayResult(res) {
      this.$router.replace({
        path: "/po-order/" + res.result,
      });
      this.close();
    },
  },
};
</script>

<style scoped>
.check-block-layer {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkout-form {
  width: 375px;
  background-color: white;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.checkout-inner {
  padding: 20px;
}

.product-image {
  width: 30%;
}

.cancel-button {
  width: calc(100% - 40px);
  margin-top: 15px;
  text-align: center;
}

.product-name {
  font-size: 15px;
  font-weight: bold;
  line-height: 38px;
  width: 223px;
  /* background-color: #eee; */
  display: inline-block;
  vertical-align: top;
}

.product-desc {
  color: #aaa;
  margin: auto;
  margin-top: 10px;
}

.price {
  /* background-color: #ddd; */
  width: 82px;
  font-size: 15px;
  margin-top: 13px;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  font-weight: 500;
}

.order-notice {
  color: #aaa;
  margin: auto;
  margin-top: 20px;
  font-size: 12px;
  line-height: 15px;
}

.checkout-line {
  height: 1px;
  background-color: #eee;
  width: 100%;
  margin: 10px auto;
}

.coupon-code {
  border: 1px solid #cacaca;
  border-radius: 5px;
  padding: 0 6px;
  line-height: 24px;
  font-size: 12px;
  color: #333;
  width: 145px;
  text-align: center;
}

.coupon-apply {
  font-size: 12px;
  line-height: 20px;
  margin-top: 3px;
  display: inline-block;
  vertical-align: top;
  color: #27c1dd;
  cursor: pointer;
  width: 52px;
}

.coupon-apply-grey {
  color: #a7a6a6;
  cursor: default;
}

.members-account {
  font-size: 12px;
  line-height: 20px;
  margin-top: 3px;
  display: inline-block;
  vertical-align: top;
  color: #666;
  /* width: 52px; */
}

.total-price {
  text-align: right;
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
}

.tips {
  color: #888;
  font-size: 12px;
  line-height: 12px;
  margin-top: 5px;
}

.tips-red {
  color: rgb(255, 102, 102);
}

.price-tips {
  color: #666;
  font-size: 12px;
  line-height: 15px;
  margin-top: 5px;
}

.right-price {
  color: #333;
  float: right;
  width: 72px;
  text-align: right;
  font-weight: 500;
}
/* 选择支付方式 */
.choose-pay-mode-wrap {
  margin-top: 10px;
  border-top: 1px dashed #ddd;
  padding: 20px 0 10px;
}

.choose-pay-mode-wrap .title {
  line-height: 20px;
  font-size: 15px;
  color: #666;
  margin-bottom: 10px;
  font-weight: 500;
}

.choose-pay-mode-wrap .option {
  display: inline-block;
  vertical-align: top;
  width: 140px;
  text-align: left;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  font-size: 14px;
}

.choose-pay-mode-wrap .option-selected {
  border: 1px solid #f5a623;
  background-image: url(/assets/images/pay/new/selected-1.png);
  background-position: right 0px top 0px;
  background-repeat: no-repeat;
  background-size: 15px;
}
.choose-pay-img {
  height: 20px;
  vertical-align: top;
  margin-right: 5px;
  margin-top: 5px;
}
.top-img {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-image: url(/assets/images/pay/bg.png);
  height: 90px;
}
.safari-wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.safari-inner {
  background-color: white;
  margin: auto;
  margin-top: 250px;
  width: 280px;
  height: 150px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.family-price-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.family-price-text {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.05px;
  color: #4f5054;
}

.family-price-text-free {
  margin-left: 4px;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.05px;
  color: #ff8415;
}

.family-price-text-plus {
  margin-left: 8px;
  margin-right: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.05px;
  color: #312c2c;
}
</style>
