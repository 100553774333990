/* eslint-disable no-unused-vars */
// 全局注册文件
//用于配置：组件的基础信息 + 组件基础方法  + 基础方法
import i18n from "@/i18n/index";

const BaseGlobal = {
  // 原user.js  i18n.t("XX.YY")
  // 年龄段-数组
  Ages: [
    { id: 0, name: i18n.t("vars.ages.all_ages"), tag: i18n.t("vars.ages.all_ages"), begin: "All", num: 0 },
    { id: 1, name: "3+", tag: i18n.t("vars.ages.preschooler"), begin: "3+", num: 3 },
    { id: 2, name: "6+", tag: i18n.t("vars.ages.kid"), begin: "6+", num: 6 },
    { id: 3, name: "9+", tag: i18n.t("vars.ages.tween"), begin: "9+", num: 9 },
    { id: 4, name: "13+", tag: i18n.t("vars.ages.teen"), begin: "13+", num: 13 },
    { id: 5, name: "16+", tag: i18n.t("vars.ages.young_adults"), begin: "16+", num: 16 },
  ],

  // 子类型-数组
  SubTypes: [
    i18n.t("vars.subTypes.scrip_screenplay"),
    i18n.t("vars.subTypes.video_games"),
    i18n.t("vars.subTypes.youTube"),
    i18n.t("vars.subTypes.strong_paragraphs"),
    i18n.t("vars.subTypes.poem"),
  ],

  getCookieName() {
    let host = window.location.host;
    if (
      host == "lphone.storybird.com" ||
      host == "l.storybird.com" ||
      host == "m.storybird.com" ||
      host == "lphonecn.storybird.com" ||
      host == "lbackstage.storybird.com" ||
      host == "localhost.storybird.com"
    ) {
      return "storybird_jwt_local";
    }
    if (/dev/.test(host)) {
      return "storybird_jwt_dev";
    }
    return "storybird_jwt";
  },
  useStripe(callback) {
    let step = 0;
    let HEAD = document.getElementsByTagName("head")[0] || document.documentElement;
    let src = "https://polyfill.io/v3/polyfill.min.js?version=3.52.1&features=fetch";
    let script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.onload = function () {
      next();
    };
    script.setAttribute("src", src);
    HEAD.appendChild(script);

    src = "https://js.stripe.com/v3/";
    script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.onload = function () {
      next();
    };
    script.setAttribute("src", src);
    HEAD.appendChild(script);

    function next() {
      step += 1;
      if (step == 2) {
        callback();
      }
    }
  },

  avatarUrl(url, username) {
    if (url && url.length > 0) {
      if (!/^http(s)?:\/\//.test(url)) {
        return "https://storybird.s3.amazonaws.com/" + url;
      } else {
        return url;
      }
    } else if (username) {
      if (/[a-zA-Z]{1}/.test(username.substr(0, 1))) {
        return (
          "https://s3.amazonaws.com/storybird/system-avatars/letter-" + username.substr(0, 1).toLowerCase() + ".png"
        );
      } else {
        return "https://s3.amazonaws.com/storybird/system-avatars/default_avatar.png";
      }
    }
    return "https://s3.amazonaws.com/storybird/system-avatars/default_avatar.png";
  },

  //原来vars.js
  // 排序分组搜索-数组（最新、新作者、一周TOP等）
  SortArr: {
    recent: i18n.t("vars.sortArr.new"),
    newauthor: i18n.t("vars.sortArr.new_authors"),
    active: i18n.t("vars.sortArr.popular_this_week"),
    popular: i18n.t("vars.sortArr.popular_all_time"),
    monthly: i18n.t("vars.sortArr.popular_this_month"),
    yearly: i18n.t("vars.sortArr.popular_this_year"),
  },

  DefaultSort: "active",
  DefaultCate: null,

  readableNum(num) {
    return num.toString().replace(/(?=(\B\d{3})+$)/g, ",");
  },

  // 截取时间字符串日期
  getServerDateStr(dateStr) {
    let array = dateStr.split(" ");
    if (!array || array.length === 0) {
      return dateStr;
    }
    let newDateStr = array[0];
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let dateArr = newDateStr.split("-");

    let day = parseInt(dateArr[2]);
    let monthIndex = parseInt(dateArr[1]) - 1;
    let year = dateArr[0];
    if (day < 10) {
      day = "0" + day;
    }
    return monthNames[monthIndex] + " " + day + ", " + year;
  },

  nltobr(txt) {
    if (!txt) {
      return "";
    }
    return txt.replace(/\n/g, "<br>");
  },

  //lang.js
  langArr: {
    en: "English",
    es: "Español",
    cn: "中文简体",
    tw: "中文繁体",
    pt: "Português",
    de: "Deutsch",
    fr: "français",
    xa: "لغة عربية",
    jp: "日本語",
    gr: "Ελληνικά",
    kr: "한국어",
    others: "Others",
  },

  GlobalCategory: [
    { CategoryId: "0", Name: i18n.t("challenge_guides.AllCategories") },
    { CategoryId: "1", Name: i18n.t("challenge_guides.Plot") },
    { CategoryId: "2", Name: i18n.t("challenge_guides.Structure") },
    { CategoryId: "3", Name: i18n.t("challenge_guides.Character") },
    { CategoryId: "4", Name: i18n.t("challenge_guides.Dialogue") },
    { CategoryId: "5", Name: i18n.t("challenge_guides.Setting") },
    { CategoryId: "7", Name: i18n.t("challenge_guides.Genre") },
    { CategoryId: "9", Name: i18n.t("challenge_guides.Craft") },
    { CategoryId: "10", Name: i18n.t("challenge_guides.Vocabulary") },
    { CategoryId: "11", Name: i18n.t("challenge_guides.Grammar") },
    { CategoryId: "12", Name: i18n.t("challenge_guides.Grammar") },
  ],
};

export default function (Vue) {
  //添加全局API
  Vue.prototype.$BaseGlobal = BaseGlobal;
}
