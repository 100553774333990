import request from "@/utils/request";
import qs from "qs";

export function CapturePaypalOrder(params = {}) {
  return request({
    url: "/api/Pay/capturePaypalOrder",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

// check Paypal支付状态
export function CheckPaypalStatus(params = {}) {
  return request({
    url: "/api/Pay/checkPaypalSubscriptionStatus",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

// go方法，已弃用
// export function CapturePaypalOrder(params = {}) {
//   return request({
//     url: "/api/paypal/CapturePaypalOrder",
//     method: "post",
//     data: params,
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
// }

// go方法，已弃用
// export function CheckPaypalStatus(params = {}) {
//   return request({
//     url: "/api/paypal/CheckPaypalStatus",
//     method: "post",
//     data: params,
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
// }

export function CaptureMonthSubscription(params = {}) {
  return request({
    url: "/api/paypal/CaptureMonthSubscription",
    method: "post",
    data: params,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
