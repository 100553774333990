import { getPlanInfo, getClassMemberCount } from "@/api/plan.js";

// plan

const state = {
  maxMember: "--",
  memberCount: "--",
  type: null,
  expireTime: null,
  createTime: null,
  expired: true,
  openId: "",
  studentCount: 0,
  teacherCount: 0,
  isInSchoolPlanFreeTrial: false,
  // Category  0:常规类型 1:免费试用类型
};

const mutations = {
  SET_PLAN_INFO: (state, planInfo) => {
    state.openId = planInfo.openId;
    state.maxMember = planInfo.MaxMember;
    state.memberCount = planInfo.MemberCount;
    state.type = planInfo.Type;
    state.expireTime = planInfo.ExpireTime;
    state.expired = planInfo.Expired;
    state.studentCount = planInfo.StudentCount;
    state.teacherCount = planInfo.TeacherCount;
    state.createTime = planInfo.CreateTime;
    state.isInSchoolPlanFreeTrial = planInfo.Category == "1" && !planInfo.Expired; // Category = 1，且未过期时。当前处于试用状态
  },

  SET_PLAN_MAX_MEMBER: (state, val) => {
    state.maxMember = val;
  },

  SET_PLAN_MEMBER_COUNT: (state, val) => {
    state.memberCount = val;
  },

  RESET_PLAN: (state) => {
    state.maxMember = "--";
    state.memberCount = "--";
    state.type = null;
    state.expireTime = null;
    state.expired = true;
    state.openId = "";
    state.studentCount = 0;
    state.teacherCount = 0;
    state.isInSchoolPlanFreeTrial = false;
  },
};

const actions = {
  setPlanIno({ commit }, plan) {
    if (plan == null) {
      commit("RESET_PLAN");
    } else {
      commit("SET_PLAN_INFO", plan);
    }
  },

  updatePlan({ rootState, state, commit }, params = {}) {
    const { forceUpdate = false } = params;
    if (state.maxMember != "--" && !forceUpdate) {
      return;
    }

    const User = rootState.user || {};
    let openId;
    //在Plan中的老师都需要显示这个
    if (User.schoolPlanOpenId && User.isTeacher) {
      openId = User.schoolPlanOpenId;
      //正常用户必须管理者才需要这个
    } else {
      openId = User.managePlanOpenId;
    }
    if (openId) {
      let obj = {
        OpenId: openId,
      };
      getPlanInfo(obj)
        .then((res) => {
          if (res && res.code === 0) {
            commit("SET_PLAN_INFO", { openId: openId, ...res.result });
          }
        })
        .catch(() => {});
    } else if (User.isTeacher) {
      getClassMemberCount()
        .then((res) => {
          commit("SET_PLAN_MAX_MEMBER", 40);
          commit("SET_PLAN_MEMBER_COUNT", res.result.ClassMemberCount);
        })
        .catch(() => {});
    }
  },

  clear({ commit }) {
    commit("RESET_PLAN");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
