import stripeCheckoutComp from "../components/shop/stripe-checkout-component.vue";
import "../components/contextmenu/index.js"; // 鼠标右键菜单

export default {
  install: (Vue) => {
    //require.context自动注册太消耗性能，改为异步加载，往后新增的组件全部设置为局部注册，尽量提高加载性能
    Vue.component("saving", () => import("@/components/Saving.vue"));
    Vue.component("spinner", () => import("@/components/Spinner.vue"));
    Vue.component("about-left", () => import("@/components/about/about-left.vue"));
    Vue.component("about-top", () => import("@/components/about/about-top.vue"));
    Vue.component("account-nav", () => import("@/components/account-nav.vue"));
    Vue.component("ad", () => import("@/components/ad.vue"));
    Vue.component("avatar", () => import("@/components/avatar.vue"));
    Vue.component("btn-more", () => import("@/components/btn-more.vue"));
    Vue.component("carousel-item", () => import("@/components/carousel-item.vue"));
    Vue.component("challenge-cover", () => import("@/components/challenge/challenge-cover.vue"));
    Vue.component("challenge-tag", () => import("@/components/challenge/challenge-tag.vue"));
    Vue.component("challenge-unit", () => import("@/components/challenge/challenge-unit.vue"));
    Vue.component("class-assign", () => import("@/components/challenge/class-assign.vue"));
    Vue.component("custome-challenge-cover", () => import("@/components/challenge/custome-challenge-cover.vue"));
    Vue.component("class-assign-edit", () => import("@/components/classes/class-assign-edit.vue"));
    Vue.component("class-create-assign", () => import("@/components/classes/class-create-assign.vue"));
    Vue.component("class-lesson", () => import("@/components/classes/class-lesson.vue"));
    Vue.component("class-nav", () => import("@/components/classes/class-nav.vue"));
    Vue.component("class-pick-bar", () => import("@/components/classes/class-pick-bar.vue"));
    Vue.component("class-select-assign", () => import("@/components/classes/class-select-assign.vue"));
    Vue.component("class-showcase-books", () => import("@/components/classes/class-showcase-books.vue"));
    Vue.component("pop-class-list", () => import("@/components/classes/pop-class-list.vue"));
    Vue.component("color-picker", () => import("@/components/color-picker/src/color-picker.vue"));
    Vue.component("comic-dialog", () => import("@/components/comic-dialog.vue"));
    Vue.component("district-form", () => import("@/components/district-form.vue"));
    Vue.component("editor-guides", () => import("@/components/editor-guides.vue"));
    Vue.component("editor-topbar", () => import("@/components/editor/editor-topbar.vue"));
    Vue.component("comment-add", () => import("@/components/feedback/comment-add.vue"));
    Vue.component("comment-item", () => import("@/components/feedback/comment-item.vue"));
    Vue.component("comment-list", () => import("@/components/feedback/comment-list.vue"));
    Vue.component("comment-top", () => import("@/components/feedback/comment-top.vue"));
    Vue.component("review-comment", () => import("@/components/feedback/review-comment.vue"));
    Vue.component("footer-right-wrap", () => import("@/components/footer-right-wrap.vue"));
    Vue.component("footer-wrap", () => import("@/components/footer-wrap.vue"));
    Vue.component("image-upload", () => import("@/components/image-upload.vue"));
    // Vue.component("invitation-join", () => import("@/components/invitation/invitation-join.vue"));
    Vue.component("main-top-bar", () => import("@/components/main-top-bar.vue"));
    Vue.component("members-tag", () => import("@/components/members/members-tag.vue"));
    Vue.component("members-top", () => import("@/components/members/members-top.vue"));
    Vue.component("new-born", () => import("@/components/new-born.vue"));
    Vue.component("pdf-comic-book", () => import("@/components/pdf/pdf-comic-book.vue"));
    Vue.component("pdf-comic-page", () => import("@/components/pdf/pdf-comic-page.vue"));
    Vue.component("pdf-flash-fiction", () => import("@/components/pdf/pdf-flash-fiction.vue"));
    Vue.component("pdf-longform-book", () => import("@/components/pdf/pdf-longform-book.vue"));
    Vue.component("pdf-longform-chapter", () => import("@/components/pdf/pdf-longform-chapter.vue"));
    Vue.component("pdf-longform-cover", () => import("@/components/pdf/pdf-longform-cover.vue"));
    Vue.component("pdf-longform-info", () => import("@/components/pdf/pdf-longform-info.vue"));
    Vue.component("pdf-picture-book-cover-print", () => import("@/components/pdf/pdf-picture-book-cover-print.vue"));
    Vue.component("pdf-picture-book-cover", () => import("@/components/pdf/pdf-picture-book-cover.vue"));
    Vue.component("pdf-picture-book-info", () => import("@/components/pdf/pdf-picture-book-info.vue"));
    Vue.component("pdf-picture-book-page", () => import("@/components/pdf/pdf-picture-book-page.vue"));
    Vue.component("pdf-picture-book-print", () => import("@/components/pdf/pdf-picture-book-print.vue"));
    Vue.component("pdf-picture-book", () => import("@/components/pdf/pdf-picture-book.vue"));
    Vue.component("pdf-poetry-book", () => import("@/components/pdf/pdf-poetry-book.vue"));
    Vue.component("pdf-poetry-page", () => import("@/components/pdf/pdf-poetry-page.vue"));
    Vue.component("plan-head", () => import("@/components/plan/plan-head.vue"));
    Vue.component("plan-nav", () => import("@/components/plan/plan-nav.vue"));
    Vue.component("pop-account", () => import("@/components/popover/pop-account.vue"));
    Vue.component("pop-assign-edit", () => import("@/components/popover/pop-assign-edit.vue"));
    Vue.component("pop-book-age", () => import("@/components/popover/pop-book-age.vue"));
    Vue.component("pop-book-cate", () => import("@/components/popover/pop-book-cate.vue"));
    Vue.component("pop-book-lang", () => import("@/components/popover/pop-book-lang.vue"));
    Vue.component("pop-book-sort", () => import("@/components/popover/pop-book-sort.vue"));
    Vue.component("pop-book-type", () => import("@/components/popover/pop-book-type.vue"));
    Vue.component("pop-bookshelf", () => import("@/components/popover/pop-bookshelf.vue"));
    Vue.component("pop-challenge-age", () => import("@/components/popover/pop-challenge-age.vue"));
    Vue.component("pop-challenge-lang", () => import("@/components/popover/pop-challenge-lang.vue"));
    Vue.component("pop-challenge-type", () => import("@/components/popover/pop-challenge-type.vue"));
    Vue.component("pop-challenge-way", () => import("@/components/popover/pop-challenge-way.vue"));
    Vue.component("pop-challenge", () => import("@/components/popover/pop-challenge.vue"));
    Vue.component("pop-class-actions", () => import("@/components/popover/pop-class-actions.vue"));
    Vue.component("pop-class-share", () => import("@/components/popover/pop-class-share.vue"));
    Vue.component("pop-compilation", () => import("@/components/popover/pop-compilation.vue"));
    Vue.component("pop-create", () => import("@/components/popover/pop-create.vue"));
    Vue.component("pop-delete", () => import("@/components/popover/pop-delete.vue"));
    Vue.component("pop-editor-choose-font", () => import("@/components/popover/pop-editor-choose-font.vue"));
    Vue.component("pop-favorite-more", () => import("@/components/popover/pop-favorite-more.vue"));
    Vue.component("pop-flag", () => import("@/components/popover/pop-flag.vue"));
    Vue.component("pop-guide-cate", () => import("@/components/popover/pop-guide-cate.vue"));
    Vue.component("pop-heart", () => import("@/components/popover/pop-heart.vue"));
    Vue.component("pop-lang", () => import("@/components/popover/pop-lang.vue"));
    Vue.component("pop-member", () => import("@/components/popover/pop-member.vue"));
    Vue.component("pop-member-info", () => import("@/components/popover/pop-member-info.vue"));
    Vue.component("pop-notification", () => import("@/components/popover/pop-notification.vue"));
    Vue.component("pop-pdf-more", () => import("@/components/popover/pop-pdf-more.vue"));
    Vue.component("pop-print-book-type", () => import("@/components/popover/pop-print-book-type.vue"));
    Vue.component("pop-print-condition", () => import("@/components/popover/pop-print-condition.vue"));
    Vue.component("pop-print-shiping-type", () => import("@/components/popover/pop-print-shiping-type.vue"));
    Vue.component("pop-read", () => import("@/components/popover/pop-read.vue"));
    Vue.component("pop-share", () => import("@/components/popover/pop-share.vue"));
    Vue.component("pop-theme", () => import("@/components/popover/pop-theme.vue"));
    Vue.component("pop-write", () => import("@/components/popover/pop-write.vue"));
    Vue.component("pop-you-writing-setting", () => import("@/components/popover/pop-you-writing-setting.vue"));
    Vue.component("pop-you", () => import("@/components/popover/pop-you.vue"));
    Vue.component("pop-topbar-more", () => import("@/components/popover/pop-topbar-more.vue"));
    Vue.component("book-categories", () => import("@/components/read/book-categories.vue"));
    Vue.component("book-list", () => import("@/components/read/book-list.vue"));
    Vue.component("book-tag", () => import("@/components/read/book-tag.vue"));
    Vue.component("book-total-data", () => import("@/components/read/book-total-data.vue"));
    Vue.component("btn-bookshelf", () => import("@/components/read/btn-bookshelf.vue"));
    Vue.component("cover-distribute", () => import("@/components/read/cover-distribute.vue"));
    Vue.component("creator-basic", () => import("@/components/read/creator-basic.vue"));
    Vue.component("creator", () => import("@/components/read/creator.vue"));
    Vue.component("detail-info", () => import("@/components/read/detail-info.vue"));
    Vue.component("expert-comment", () => import("@/components/read/expert-comment.vue"));
    Vue.component("flashfiction-cover", () => import("@/components/read/flashfiction-cover.vue"));
    Vue.component("info-distribute", () => import("@/components/read/info-distribute.vue"));
    Vue.component("interact-btn-group", () => import("@/components/read/interact-btn-group.vue"));
    Vue.component("longform-cover", () => import("@/components/read/longform-cover.vue"));
    Vue.component("no-books", () => import("@/components/read/no-books.vue"));
    Vue.component("picture-book-pages", () => import("@/components/read/picture-book-pages.vue"));
    Vue.component("picturebook-cover", () => import("@/components/read/picturebook-cover.vue"));
    Vue.component("poem-page", () => import("@/components/read/poem-page.vue"));
    Vue.component("read-nav", () => import("@/components/read/read-nav.vue"));
    Vue.component("refuse-read", () => import("@/components/read/refuse-read.vue"));
    Vue.component("slick-bar", () => import("@/components/read/slick-bar.vue"));
    Vue.component("summary-info", () => import("@/components/read/summary-info.vue"));
    Vue.component("search-tag", () => import("@/components/search/search-tag.vue"));
    //支付组件：不能异步注册
    Vue.component("stripe-checkout-component", stripeCheckoutComp);
    // Vue.component("stripe-checkout-component", () => import("@/components/shop/stripe-checkout-component.vue"));
    Vue.component("slider-item", () => import("@/components/slider/slider-item.vue"));
    Vue.component("slider", () => import("@/components/slider/slider.vue"));
    Vue.component("tag", () => import("@/components/tag.vue"));
    Vue.component("user-medal", () => import("@/components/user-medal.vue"));
    Vue.component("version-select", () => import("@/components/version-select.vue"));
    Vue.component("write-comment", () => import("@/components/write-comment.vue"));
    Vue.component("write-tag", () => import("@/components/write-tag.vue"));
    Vue.component("badge-icon", () => import("@/components/yourstuff/badge-icon.vue"));
    Vue.component("book-static", () => import("@/components/yourstuff/book-static.vue"));
    Vue.component("favorite-content-tab", () => import("@/components/yourstuff/favorite-content-tab.vue"));
    Vue.component("print-tabs", () => import("@/components/yourstuff/print-tabs.vue"));
    Vue.component("progress-circle", () => import("@/components/yourstuff/progress-circle.vue"));
    Vue.component("readed-book", () => import("@/components/yourstuff/readed-book.vue"));
    Vue.component("regular-tabs", () => import("@/components/yourstuff/regular-tabs.vue"));
    Vue.component("yourstuff-outtab", () => import("@/components/yourstuff/yourstuff-outtab.vue"));
    Vue.component("main-bottom-bar", () => import("@/components/main-bottom-bar.vue"));
    Vue.component("home-ad", () => import("@/components/home-ad.vue"));
    Vue.component("home-carousel-item", () => import("@/components/banner/home-carousel-item.vue"));
    Vue.component("user-info", () => import("@/components/user/user-info.vue"));
    Vue.component("search-input", () => import("@/components/search-input.vue"));
    Vue.component("language-tool-input", () => import("@/components/editor/language-tool/language-tool-input.vue"));
    Vue.component("language-tool-menu", () => import("@/components/editor/language-tool/language-tool-menu.vue"));
    Vue.component("yourstuff-tags", () => import("@/components/yourstuff/yourstuff-tags.vue"));
    Vue.component("hashtag", () => import("@/components/hashtag.vue"));
    Vue.component("profile", () => import("@/components/yourstuff/profile.vue"));
    Vue.component("pop-profile", () => import("@/components/popover/pop-profile.vue"));
    Vue.component("redeem", () => import("@/components/yourstuff/redeem.vue"));
    Vue.component("buy-review-ticket", () => import("@/components/yourstuff/buy-review-ticket.vue"));
    Vue.component("pop-profile-more-menu", () => import("@/components/popover/pop-profile-more-menu.vue"));
    Vue.component("google-register-form", () => import("@/components/user/google-register-form.vue"));
    Vue.component("btn-feedback", () => import("@/components/btn-feedback.vue"));
    Vue.component("step", () => import("@/components/step.vue"));
    Vue.component("picture-book-cover", () =>
      import("@/components/read/read-book/picture-book/picture-book-cover.vue"),
    );
    Vue.component("picture-book-intro", () =>
      import("@/components/read/read-book/picture-book/picture-book-intro.vue"),
    );
    Vue.component("picture-book-page", () => import("@/components/read/read-book/picture-book/picture-book-page.vue"));
    Vue.component("picture-book-endpage", () =>
      import("@/components/read/read-book/picture-book/picture-book-endpage.vue"),
    );
    Vue.component("use-guidance", () => import("@/components/yourstuff/use-guidance.vue"));
    Vue.component("free-trial-guidance", () => import("@/components/yourstuff/free-trial-guidance.vue"));
    Vue.component("school-plan-statistical", () => import("@/components/yourstuff/school-plan-statistical.vue"));
    Vue.component("pop-member-profile", () => import("@/components/popover/pop-member-profile.vue"));
    Vue.component("empty", () => import("@/components/empty.vue"));
    Vue.component("select-cover-type", () => import("@/components/pdf/select-cover-type.vue"));
    Vue.component("book-cover-infos", () => import("@/components/pdf/book-cover-infos.vue"));
    Vue.component("pdf-viewer", () => import("@/components/pdf/pdf-viewer.vue"));
    Vue.component("checkout-preview", () => import("@/components/checkout/checkout-preview.vue"));
    Vue.component("checkout-shipping", () => import("@/components/checkout/checkout-shipping.vue"));
    Vue.component("checkout-shipping-method", () => import("@/components/checkout/checkout-shipping-method.vue"));
    Vue.component("checkout-confim", () => import("@/components/checkout/checkout-confim.vue"));
    Vue.component("editor-right-panel", () => import("@/components/editor/editor-right-panel.vue"));
    Vue.component("editor-illustration-wrapper", () => import("@/components/editor/editor-illustration-wrapper.vue"));
    Vue.component("editor-pixabay-wrapper", () => import("@/components/editor/editor-pixabay-wrapper.vue"));
    Vue.component("editor-favotite-wrapper", () => import("@/components/editor/editor-favotite-wrapper.vue"));
    Vue.component("editor-upload-artwork", () => import("@/components/editor/editor-upload-artwork.vue"));
    Vue.component("editor-search-artwork", () => import("@/components/editor/editor-search-artwork.vue"));
    Vue.component("editor-left-panel", () => import("@/components/editor/editor-left-panel.vue"));
    Vue.component("book-cover", () => import("@/components/editor/pages/book-cover.vue"));
    Vue.component("book-page", () => import("@/components/editor/pages/book-page.vue"));
    Vue.component("page-layout", () => import("@/components/editor/pages/page-layout.vue"));
    Vue.component("tabs", () => import("@/components/tabs.vue"));
    Vue.component("editor-longform-chapter-panel", () =>
      import("@/components/editor/editor-longform-chapter-panel.vue"),
    );
    Vue.component("create-class-comp", () => import("@/components/classes/create-class-comp.vue"));
    Vue.component("generate-class-student", () => import("@/components/classes/generate-class-student.vue"));
    Vue.component("invite-student", () => import("@/components/classes/invite-student.vue"));
    Vue.component("display-qrcode", () => import("@/components/classes/display-qrcode.vue"));

    // 通用组件
    Vue.component("common-switch", () => import("@/components/common/switch.vue"));

    // 批改作业
    Vue.component("homework-topbar", () => import("@/components/homework/homework-topbar.vue"));
    Vue.component("page-index-picturebook", () => import("@/components/homework/page-index-picturebook.vue"));
    Vue.component("diffs-page-picturebook", () => import("@/components/homework/diffs-page-picturebook.vue"));
    Vue.component("book-rate-comment", () => import("@/components/homework/book-rate-comment.vue"));
    Vue.component("teacher-comment", () => import("@/components/homework/teacher-comment.vue"));
  },
};
