<template>
  <div id="app">
    <!-- <div v-if="showCustomTheme" class="christmas">
      <img class="chis-bg" src="/assets/images/christmas/c-bg.png" />
    </div> -->
    <main-top-bar v-if="showNav" :page="page" />
    <!--页面返回不刷新-->
    <keep-alive>
      <router-view :key="$route.fullPath" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view :key="$route.fullPath" v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
// import { initialData } from "@/api/common.js";
import { CarouselShare } from "@/api/common.js";
import { showTeacherSurvey } from "@/utils/element-ui/notify.js";

// 不显示FAQ路径
const hideFaqRouters = [
  "comic-editor",
  "flashfiction-editor",
  "longformbook-editor",
  "picturebook-editor",
  "poetry-editor",
];

export default {
  name: "MainPage",
  components: {
    // MainTopBar,
  },
  data() {
    return {
      showNav: true,
      hideNavPath: [
        "picturebook-editor",
        "longformbook-editor",
        "comic-editor",
        "flashfiction-editor",
        "poetry-editor",
        "pdftest1",
        "embed",
        "signin",
        "forgot-password",
        "signup",
        "read-book",
        "student-sign-in",
        "students-code",
        "marking-picturebook",
        "homework-picturebook",
      ],
      page: null,
      showCustomTheme: false,
      showSurveyNotify: false, // 是否已显示调查通知
    };
  },

  computed: {
    User() {
      return this.$store.getters.User;
    },
  },

  watch: {
    $route: function (route) {
      this.showNav = !this.hideNavPath.includes(route.name);
      this.setCustomThemeConf();
      let forceHide = false;
      if (hideFaqRouters.includes(route.name)) {
        forceHide = true;
      }
      Vue.prototype.$Intercom.Update(this.User, forceHide);
      // this.handleSurveyNotify();
    },
  },
  mounted() {
    let route = this.$route;
    this.showNav = !this.hideNavPath.includes(route.name);
    this.initMetaInfo(route);
    this.carouselShare(route);

    const referrer = document && document.referrer ? document.referrer : undefined;
    if (
      referrer &&
      (referrer.indexOf("https://storybird.com/") === -1 || referrer.indexOf("https://www.storybird.com/") === -1)
    ) {
      // 过滤本站跳转
      const obj = {
        path: route.fullPath,
        referrer: referrer,
      };
      // 记录referrer路由事件日志
      this.$logEvent.trace(this.$logEvent.EventKey.ROUTE_REFERRER, obj);
    }
    this.setCustomThemeConf();
  },
  methods: {
    // 社交分享统计
    carouselShare(route) {
      // 新版处理：记录到amplitude
      // facebook：在URL有个query参数 -> fbclid
      //示例：https://www.storybird.com/?fbclid=IwAR12Q9S9qhdVPsHGM_bYYqLpktu8T8QBsDKeh6i6v_07OWw_-fIvGsbeIWk
      let routePath = route.path.toLowerCase();
      let query = route.query || {};
      let params = route.params || {};
      let social_id = params.share_social_params;
      const fbclid = query.fbclid;

      if (fbclid && fbclid.length > 0) {
        // facebook分享链接 -> 新版log
        const obj = { fbclid: fbclid, path: route.fullPath };
        if (social_id && social_id.length > 0) {
          obj["social_id"] = social_id;
        }
        this.$logEvent.trace(this.$logEvent.EventKey.SOCIAL_SHARE_FACEBOOK, obj);
      } else if (social_id && social_id.length > 0 && routePath.indexOf("/facebook/") > 0) {
        // facebook分享链接 -> 旧版log
        this.$logEvent.trace(this.$logEvent.EventKey.SOCIAL_SHARE_FACEBOOK, {
          social_id: social_id,
          path: route.fullPath,
        });
      } else if (social_id && social_id.length > 0 && routePath.indexOf("/twitter/") > 0) {
        // twitter分享链接 -> 旧版log
        this.$logEvent.trace(this.$logEvent.EventKey.SOCIAL_SHARE_TWITTER, {
          social_id: social_id,
          path: route.fullPath,
        });
      } else if (social_id && social_id.length > 0 && routePath.indexOf("/instagram/") > 0) {
        // instagram分享链接 -> 旧版log
        this.$logEvent.trace(this.$logEvent.EventKey.SOCIAL_SHARE_INSTAGRAM, {
          social_id: social_id,
          path: route.fullPath,
        });
      }

      // 旧版处理：后端记录cookie，新增匹配fbclid使得数据更准确
      if ((social_id && social_id.length > 0) || (fbclid && fbclid.length > 0)) {
        // 通知后端，记录分享的相关cookie
        let fullPath = route.fullPath;
        if (routePath.indexOf("/facebook/") === -1) {
          // Facebook分享渠道：没有Facebook路由，但是有fbclid参数
          fullPath = routePath === "/" ? "/Facebook/0" : routePath + "/Facebook/0";
          let queryStr = "?";
          for (const key in route.query) {
            if (queryStr.length !== 1) {
              queryStr = queryStr + "&" + key + "=" + route.query[key];
            } else {
              queryStr = queryStr + key + "=" + route.query[key];
            }
          }
          if (queryStr !== "?") {
            fullPath = fullPath + queryStr;
          }
        }
        CarouselShare(fullPath)
          .then(() => {
            // 设置cookie成功
          })
          .catch(() => {
            // 设置cookie失败
          });
      }
    },
    // 初始化浏览器meta数据
    initMetaInfo() {
      // let path = route.fullPath;
      // path = path.split("?")[0];
      // initialData(path)
      //   .then((res) => {
      //     this.setMetaDatas(res.result);
      //   })
      //   .catch(() => {});
    },

    setMetaDatas(result = {}) {
      // title
      window.document.title = result.SiteTitle;
      // description
      let metaDescription = document.head.children.description;
      if (metaDescription == null) {
        metaDescription = document.createElement("meta");
        metaDescription.name = "description";
        document.head.append(metaDescription);
      }
      metaDescription.content = result.SiteDesc;
      // og:title
      let list = document.querySelectorAll('meta[property="og:title"]') || [];
      if (!list || list.length === 0) {
        let meta = document.createElement("meta");
        meta.property = "og:title";
        meta.content = result.SiteTitle;
        document.head.append(meta);
      } else {
        list[0].content = result.SiteTitle;
      }
      // og:description
      list = document.querySelectorAll('meta[property="og:description"]') || [];
      if (!list || list.length === 0) {
        let meta = document.createElement("meta");
        meta.property = "og:description";
        meta.content = result.SiteDesc;
        document.head.append(meta);
      } else {
        list[0].content = result.SiteDesc;
      }
      // og:image
      list = document.querySelectorAll('meta[property="og:image"]') || [];
      if (!list || list.length === 0) {
        let meta = document.createElement("meta");
        meta.property = "og:image";
        meta.content = result.NormalShare ? result.SiteImg2 : result.SiteImg;
        document.head.append(meta);
      } else {
        list[0].content = result.NormalShare ? result.SiteImg2 : result.SiteImg;
      }
      //twitter:title
      list = document.querySelectorAll('meta[name="twitter:title"]') || [];
      if (!list || list.length === 0) {
        let meta = document.createElement("meta");
        meta.name = "twitter:title";
        meta.content = result.SiteTitle;
        document.head.append(meta);
      } else {
        list[0].content = result.SiteTitle;
      }
      //twitter:description
      list = document.querySelectorAll('meta[name="twitter:description"]') || [];
      if (!list || list.length === 0) {
        let meta = document.createElement("meta");
        meta.name = "twitter:description";
        meta.content = result.SiteDesc;
        document.head.append(meta);
      } else {
        list[0].content = result.SiteDesc;
      }
      //twitter:card
      list = document.querySelectorAll('meta[name="twitter:card"]') || [];
      if (!list || list.length === 0) {
        let meta = document.createElement("meta");
        meta.name = "twitter:card";
        meta.content = result.TwitterShareType;
        document.head.append(meta);
      } else {
        list[0].content = result.TwitterShareType;
      }
      //twitter:site
      list = document.querySelectorAll('meta[name="twitter:site"]') || [];
      if (!list || list.length === 0) {
        let meta = document.createElement("meta");
        meta.name = "twitter:site";
        meta.content = "@storybird";
        document.head.append(meta);
      } else {
        list[0].content = "@storybird";
      }
      //twitter:image
      list = document.querySelectorAll('meta[name="twitter:image"]') || [];
      if (!list || list.length === 0) {
        let meta = document.createElement("meta");
        meta.name = "twitter:image";
        meta.content = result.NormalShare ? result.SiteImg2 : result.SiteImg;
        document.head.append(meta);
      } else {
        list[0].content = result.NormalShare ? result.SiteImg2 : result.SiteImg;
      }
      //twitter:site:id
      list = document.querySelectorAll('meta[name="twitter:site:id"]') || [];
      if (!list || list.length === 0) {
        let meta = document.createElement("meta");
        meta.name = "twitter:site:id";
        meta.content = "17815345";
        document.head.append(meta);
      } else {
        list[0].content = "17815345";
      }
      // og:site_name
      list = document.querySelectorAll('meta[property="og:site_name"]') || [];
      if (!list || list.length === 0) {
        let meta = document.createElement("meta");
        meta.property = "og:site_name";
        meta.content = "Storybird";
        document.head.append(meta);
      } else {
        list[0].content = "Storybird";
      }
    },

    // 设置自定义主题状态配置
    setCustomThemeConf() {
      let route = this.$route;
      // 路由名称为空、或者首页时，不显示自定义主题
      this.showCustomTheme = route.name !== null && route.name !== "home";
    },

    // 判断是否显示调查通知，只有老师账号才显示，关闭后不再显示
    handleSurveyNotify() {
      if (this.showSurveyNotify) {
        return;
      }
      if (!this.User.isLogin) {
        return;
      }
      if (!this.User.isTeacher) {
        return;
      }
      this.showSurveyNotify = true;
      if (this.User.isMember) {
        showTeacherSurvey("https://www.surveymonkey.com/r/3XMKBCW");
      } else {
        showTeacherSurvey("https://www.surveymonkey.com/r/VPBJRNH");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: "Helvetica Neue", Helvetica, Arial, Helmet, Freesans, PingFang, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  text-align: left;
  color: #2c3e50;
}

.nav-root {
  width: 100%;
}

.christmas {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: -1;
  background: linear-gradient(180deg, #def4fc 10%, #a2e5ff);
}

.christmas:after {
  z-index: 2;
  animation: snowY 25s linear infinite, snowX 55s linear infinite;
  height: 100%;
  background-position: center 0;
  background-image: url(/assets/images/snow.svg);
  background-size: 1438px 812px;
}

.christmas:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  pointer-events: none;
  content: "";
}

.chis-bg {
  position: absolute;
  width: 100%;
  bottom: 0;
}

@keyframes snowY {
  0% {
    background-position-y: 0;
  }

  to {
    background-position-y: 2436px;
  }
}

@keyframes snowX {
  0% {
    background-position-x: 0;
  }

  to {
    background-position-x: 2876px;
  }
}
</style>
