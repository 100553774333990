import request from "@/utils/request";
import qs from "qs";

export function getNotificationCount() {
  return request({
    url: "/api/Notification/GetNotificationCount",
    method: "post",
    // data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function getNotificationList(params = {}) {
  return request({
    url: "/api/Notification/GetNotification",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function emailCancelNotification(params = {}) {
  return request({
    url: "/api/EmailNotification/CancelEmailNotification",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}
