import request from "@/utils/request";
import qs from "qs";

// 登录接口
export function SignIn(url, params) {
  return request({
    url: url,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

// 获取用户信息
export function userInfo() {
  return request({
    url: `/api/User/GetUserInfo`,
    method: "get",
    // data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

// 忘记密码
export function forgotPassword(params) {
  return request({
    url: `/api/User/ForgotPassword`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

// 检查用户名是否已存在
export function checkUserNameExist(params) {
  return request({
    url: `/api/User/CheckUserNameExist`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

// 检查邮箱
export function checkEmailExist(params) {
  return request({
    url: `/api/User/CheckEmailExist`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

// 注册
export function signUpRequest(params) {
  return request({
    url: `/api/User/SignUp`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

// 重发邮件
export function resendActiveEmailWhenSignUp(params) {
  return request({
    url: `/api/User/ResendActiveEmailWhenSignUp`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

//
export function getInviteTypeName(params) {
  return request({
    url: `/api/User/GetInviteTypeName`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

//更改主题
export function ChangeTheme(params) {
  return request({
    url: `/api/User/ChangeTheme`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

//修改密码
export function ChangePwd(params) {
  return request({
    url: `/api/User/ChangePwd`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

//删除账号
export function DeleteAccount(params) {
  return request({
    url: `/api/User/DeleteAccount`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

//更新头像
export function UpdateAvatar(params) {
  return request({
    url: `/api/User/UpdateAvatar`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function ChangeEmailNotificationSetting(params) {
  return request({
    url: `/api/User/ChangeEmailNotificationSetting`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetEmailNotificationSettings(params) {
  return request({
    url: `/api/User/GetEmailNotificationSettings`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function CompeleteInfo(params) {
  return request({
    url: `/api/User/CompeleteInfo`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function ChangeEmail(params) {
  return request({
    url: `/api/User/ChangeEmail`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function ResendActiveEmail(params) {
  return request({
    url: `/api/User/ResendActiveEmail`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetForgotInfo(params) {
  return request({
    url: `/api/User/GetForgotInfo`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GenerateNewPwd(params) {
  return request({
    url: `/api/User/GenerateNewPwd`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetActiveInfo(params) {
  return request({
    url: `/api/User/GetActiveInfo`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function ActiveEmail(params) {
  return request({
    url: `/api/User/ActiveEmail`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function UpdateInfo(params) {
  return request({
    url: `/api/User/UpdateInfo`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

// google账号注册
export function registerForGoogle(params) {
  return request({
    url: `/api/User/GoogleSignUp`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

// 获取用户推荐码
export function GetUserRerralCode() {
  return request({
    url: `/api/v1/user/invitation_code`,
    method: "get",
  });
}

// 校验推荐码
export function CheckReferralCode(params = {}) {
  return request({
    url: `/api/v1/user/invitation_code/check`,
    method: "post",
    data: params,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// 校验免费试用邮箱
export function CheckFreetrialEmail(params = {}) {
  return request({
    url: `/api/v2_1/user/CheckFreeTrialEmail`,
    method: "post",
    data: params,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// 获取用户Plan统计数据
export function GetMyPlanInfomation() {
  return request({
    url: `/api/v2_1/YourStuff/GetMyPlanInfomation`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// 班级学生登录接口
export function StudentLogin(params) {
  return request({
    url: `/api/User/studentSignIn`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}
