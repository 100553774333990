import { getBest } from "@/api/best.js";

// 年度top 10

const state = {
  bestList: [],
};

const mutations = {
  // 保存列表
  SET_BEST_LIST: (state, params = []) => {
    state.bestList = params;
  },
};

const actions = {
  // 获取Best列表
  loadBestList({ commit }) {
    return new Promise((resolve, reject) => {
      getBest()
        .then((res) => {
          const list = JSON.parse(JSON.stringify(res.result || []));
          commit("SET_BEST_LIST", list);
          resolve(list);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
