import request from "@/utils/request";
import qs from "qs";

export function getPlanInfo(params) {
  return request({
    url: "/api/Plan/GetPlanInfo",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function getClassMemberCount() {
  return request({
    url: "/api/Classes/GetClassMemberCount",
    method: "post",
    // data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetInvitation(params) {
  return request({
    url: "/api/Plan/GetInvitation",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function DeleteInvite(params) {
  return request({
    url: "/api/Plan/DeleteInvite",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function DeletePlanMember(params) {
  return request({
    url: "/api/Plan/DeletePlanMember",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function SavePlanMember(params) {
  return request({
    url: "/api/Plan/SavePlanMember",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetPlanMember(params) {
  return request({
    url: "/api/Plan/GetPlanMember",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function Invite(params) {
  return request({
    url: "/api/Plan/Invite",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

// 重新发送邀请邮件
export function ResendInvite(params) {
  return request({
    url: "/api/Plan/ResendInvite",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function AddMember(params) {
  return request({
    url: "/api/Plan/AddMember",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function CheckCanAdd(params) {
  return request({
    url: "/api/Plan/CheckCanAdd",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function DeletePlanTeacher(params) {
  return request({
    url: "/api/Plan/DeletePlanTeacher",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function SavePlanTeacher(params) {
  return request({
    url: "/api/Plan/SavePlanTeacher",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function AddTeacher(params) {
  return request({
    url: "/api/Plan/AddTeacher",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetPlanTeacher(params) {
  return request({
    url: "/api/Plan/GetPlanTeacher",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetAddMemberPirce(params) {
  return request({
    url: "/api/Plan/GetAddMemberPirce",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function MakePaySession(params) {
  return request({
    url: "/api/Pay/MakePaySession",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetPlanClass(params) {
  return request({
    url: "/api/Plan/GetPlanClass",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function DismissPlanClass(params) {
  return request({
    url: "/api/Plan/DismissPlanClass",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetPlanStudent(params) {
  return request({
    url: "/api/Plan/GetPlanStudent",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function DeletePlanStudent(params) {
  return request({
    url: "/api/Plan/DeletePlanStudent",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function getSchoolInformation() {
  return request({
    url: "/api/plan/getLastSchoolInformation",
    method: "get",
  });
}

export function saveSchoolInformation(params) {
  return request({
    url: "/api/plan/SaveSchoolInformation",
    method: "post",
    data: params,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function ApplyFreeTrialSchoolInformation(params) {
  return request({
    url: "/api/plan/ApplyFreeTrialSchoolInformation",
    method: "post",
    data: params,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
