const IntercomUpdater = {
  Update(userInfo, forceHide = false) {
    const params = {
      hide_default_launcher: true, // 只有老师账号才显示
    };
    if (userInfo && userInfo.isLogin) {
      params["name"] = userInfo.username;
      params["user_id"] = userInfo.uid;
      params["email"] = userInfo.email;
      params["hide_default_launcher"] = userInfo.accountType != 2;
    }
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "nttkf4yf",
      ...params,
    };
    Intercom && Intercom("update", { hide_default_launcher: forceHide ? true : params["hide_default_launcher"] });
  },
};

export default IntercomUpdater;
