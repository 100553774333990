import request from "@/utils/request";
import qs from "qs";

export function exchange(url, params) {
  return request({
    url: url,
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function crownsList(params) {
  return request({
    url: "/api/YourStuff/CrownsList",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function ticketList(params) {
  return request({
    url: "/api/YourStuff/TicketList",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetBadges(params) {
  return request({
    url: "/api/YourStuff/GetBadges",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetChallengeProgress(params) {
  return request({
    url: "/api/YourStuff/GetChallengeProgress",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function WriteList(params) {
  return request({
    url: "/api/YourStuff/WriteList",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function ReadList(params) {
  return request({
    url: "/api/YourStuff/ReadList",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function Badges(params) {
  return request({
    url: "/api/YourStuff/Badges",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetFavoritesBooks(params) {
  return request({
    url: "/api/YourStuff/GetFavoritesBooks",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetFavoritesBooksNum(params) {
  return request({
    url: "/api/YourStuff/GetFavoritesBooksNum",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetBookInfo(params) {
  return request({
    url: "/api/YourStuff/GetBookInfo",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function MyComicArtwork(params) {
  return request({
    url: "/api/YourStuff/MyComicArtwork",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function AddComicArtwork(params) {
  return request({
    url: "/api/YourStuff/AddComicArtwork",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function SaveComicPage(params) {
  return request({
    url: "/api/YourStuff/SaveComicPage",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function PublishComic(params) {
  return request({
    url: "/api/YourStuff/PublishComic",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function UnPublishComic(params) {
  return request({
    url: "/api/YourStuff/UnPublishComic",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function TransferComic(params) {
  return request({
    url: "/api/YourStuff/TransferComic",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetComicArtworkDetail(params) {
  return request({
    url: "/api/YourStuff/GetComicArtworkDetail",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function ConvertToLongForm(params) {
  return request({
    url: "/api/YourStuff/ConvertToLongForm",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetFavoritesArtworks(params) {
  return request({
    url: "/api/YourStuff/GetFavoritesArtworks",
    method: "POST",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function getBookCount() {
  return request({
    url: `/api/YourStuff/getWriteBookCount`,
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function CheckArtworks(params = {}) {
  return request({
    url: "/api/v2_1/YourStuff/CheckArtworks",
    method: "post",
    data: params,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
