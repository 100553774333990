import { GetToken, GetUploadArtworkToken } from "@/api/upload.js";

// aws上传token

const state = {
  UploadToken: null, // 需要登录
  ArtworkToken: null, // 需要登录，且必须是艺术家
};

const mutations = {
  SET_UPLOAD_TOKEN: (state, params) => {
    state.UploadToken = params;
  },

  SET_ARTWORK_TOKEN: (state, params) => {
    state.ArtworkToken = params;
  },

  RESET_TOKEN: (state) => {
    state.UploadToken = null;
    state.ArtworkToken = null;
  },
};

const actions = {
  // 获取AWS 上传文件token
  getToken({ commit }) {
    return new Promise((resolve, reject) => {
      GetToken({})
        .then((res) => {
          const token = {
            Credentials: res.result.Credentials,
            S3Region: res.result.S3Region,
            S3Bucket: res.result.S3Bucket,
            S3Prefix: res.result.S3Prefix,
          };
          commit("SET_UPLOAD_TOKEN", token);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // 获取上传artwork token， 需要登录，且必须是艺术家
  getArtworkToken({ commit }) {
    return new Promise((resolve, reject) => {
      GetUploadArtworkToken({})
        .then((res) => {
          const token = {
            Credentials: res.result.Credentials,
            S3Region: res.result.S3Region,
            S3Bucket: res.result.S3Bucket,
            S3Prefix: res.result.S3Prefix,
          };
          commit("SET_ARTWORK_TOKEN", token);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  resetToken({ commit }) {
    commit("RESET_TOKEN");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
