import request from "@/utils/request";
import qs from "qs";

export function GetExchangeRate() {
  return request({
    url: "/api/User/GetExchangeRate",
    method: "post",
    // data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function PrePay(params) {
  return request({
    url: "/api/User/PrePay",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function CheckCoupon(params) {
  return request({
    url: "/api/Coupon/CheckCoupon",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function Pay(params) {
  return request({
    url: "/api/User/Pay",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetMembershipDiscount(params) {
  return request({
    url: "/api/User/GetMembershipDiscount",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetMinSchoolPlanNum() {
  return request({
    url: "/api/User/GetMinSchoolPlanNum",
    method: "post",
    // data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function MakePaySession(params) {
  return request({
    url: "/api/Pay/MakePaySession",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function DistrictEmail(params) {
  return request({
    url: "/api/User/DistrictEmail",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetPayOrder(params) {
  return request({
    url: "/api/User/GetPayOrder",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function CheckPayStatus(params) {
  return request({
    url: "/api/Pay/CheckPayStatus",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetCustomOrder(params) {
  return request({
    url: "/api/CustomOrder/GetCustomOrder",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}
