import { getBasketItems } from "@/api/basket.js";

// 购物车

const state = {
  basketItems: [],
  itemCount: 0,
  basketLoaded: false,
};

const mutations = {
  // 保存列表
  SET_BASKRT_LIST: (state, params = []) => {
    state.basketItems = params;
  },

  // 保存商品数量
  SET_ITEM_COUNT: (state, list = []) => {
    let count = 0;
    for (let i = 0; i < list.length; i++) {
      count += list[i].Quantity * 1;
    }
    state.itemCount = count;
  },

  SET_BASKRT_LOADED: (state, val = false) => {
    state.basketLoaded = val;
  },
};

const actions = {
  // 获取Basket列表
  loadBasketList({ commit }) {
    return new Promise((resolve, reject) => {
      getBasketItems()
        .then((res) => {
          const list = JSON.parse(JSON.stringify(res.result || []));
          commit("SET_BASKRT_LIST", list);
          commit("SET_ITEM_COUNT", list);
          commit("SET_BASKRT_LOADED", true);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  clear({ commit }) {
    commit("SET_BASKRT_LIST", []);
    commit("SET_ITEM_COUNT", []);
    commit("SET_BASKRT_LOADED", false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
