export function applyTheme(theme) {
  if (!theme || theme == "") {
    theme = "standard";
  }

  if (theme != "standard") {
    document.getElementsByTagName("body")[0].style.backgroundImage =
      "url(https://s3.amazonaws.com/storybird/backgrounds/" + theme + ".jpg)";
  } else {
    document.getElementsByTagName("body")[0].style.backgroundImage = "";
  }
}

export function setBodyTheme(url, color) {
  document.getElementsByTagName("body")[0].style.backgroundImage = "url(" + url + ")";
  document.getElementsByTagName("body")[0].style.backgroundColor = color;
}

export function resetBodyTheme() {
  document.getElementsByTagName("body")[0].style.backgroundImage = "";
}

export function brightness(hex) {
  const hexStr = convertToFullColorCode(hex);
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexStr);
  return (
    (parseInt(result[1], 16) / 255) * 0.299 +
    (parseInt(result[2], 16) / 255) * 0.587 +
    (parseInt(result[2], 16) / 255) * 0.114
  );
}

function convertToFullColorCode(colorCode) {
  // 使用正则表达式提取颜色代码中的每个字符
  const regex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const matches = colorCode.match(regex);
  // 如果匹配到了 3 个字符，则将其每个字符重复一遍
  if (matches) {
    return `#${matches[1]}${matches[1]}${matches[2]}${matches[2]}${matches[3]}${matches[3]}`;
  }
  // 如果颜色代码格式不正确，则直接返回原来的代码
  return colorCode;
}
