var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "check-block-layer" },
    [
      _vm.safariUrl
        ? _c(
            "div",
            {
              staticClass: "safari-wrap",
              staticStyle: {
                "background-color": "rgba(0, 0, 0, 0.7)",
                "z-index": "999999999",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "safari-inner",
                  staticStyle: { "flex-direction": "column" },
                },
                [
                  _c("div", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("members.shop.explorerBlocked")) + " "
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-blue",
                      staticStyle: { "margin-top": "10px" },
                      on: { click: _vm.openPaymentUrlClick },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("members.shop.ContinuePayment")) +
                          " "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "checkout-form" }, [
        _c("div", { staticClass: "top-img" }, [
          _c("img", {
            staticClass: "product-image",
            attrs: { src: _vm.productImage },
          }),
        ]),
        _c("div", { staticClass: "checkout-inner" }, [
          _vm.payItem === "PLAN_ADD_MEMBER"
            ? _c("div", [
                _c("div", { staticClass: "product-name" }, [
                  _vm._v(_vm._s(_vm.productName)),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "price",
                    staticStyle: { width: "91px", float: "right" },
                  },
                  [
                    _c("span", [_vm._v("$")]),
                    _vm._v(
                      " " + _vm._s(_vm.toThousands(_vm.originalPrice)) + " "
                    ),
                  ]
                ),
              ])
            : _vm.isSchoolPlanItem
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "product-name",
                    staticStyle: { width: "100%", "margin-bottom": "5px" },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.productName) + " "),
                    _c("span", { staticStyle: { "font-weight": "400" } }, [
                      _vm._v("/One Year"),
                    ]),
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.numberOfMembers,
                      expression: "numberOfMembers",
                    },
                  ],
                  staticClass: "coupon-code",
                  style: {
                    "box-shadow": _vm.byTips2,
                    "border-color": _vm.checkNum(_vm.numberOfMembers)
                      ? ""
                      : "#FF9592",
                  },
                  attrs: { placeholder: _vm.tips2, disabled: _vm.memberCount },
                  domProps: { value: _vm.numberOfMembers },
                  on: {
                    blur: function ($event) {
                      _vm.tips2 = "Number of members"
                    },
                    focus: function ($event) {
                      _vm.tips2 = ""
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.numberOfMembers = $event.target.value
                    },
                  },
                }),
                _c("div", { staticClass: "members-account" }, [
                  _vm._v("Members"),
                ]),
                _vm.checkNum(_vm.numberOfMembers) && false
                  ? _c(
                      "div",
                      {
                        staticClass: "price",
                        staticStyle: {
                          "margin-top": "6px",
                          "font-size": "13px",
                          color: "#000",
                        },
                      },
                      [
                        _c("span", [_vm._v("$")]),
                        _vm._v(
                          " " + _vm._s(_vm.toThousands(_vm.originalPrice)) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.checkNum(_vm.numberOfMembers) && false
                  ? _c(
                      "div",
                      {
                        staticClass: "price",
                        staticStyle: {
                          "margin-top": "6px",
                          "font-size": "13px",
                          color: "#000",
                        },
                      },
                      [_c("span", [_vm._v("$")]), _vm._v(" ---- ")]
                    )
                  : _vm._e(),
                !_vm.checkNum(_vm.numberOfMembers)
                  ? _c(
                      "div",
                      {
                        class: {
                          tips: 1,
                          "tips-red": !_vm.checkNum(_vm.numberOfMembers),
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.payItem == "FAMILY_PLAN"
                                ? "Please enter a number between 2-5"
                                : _vm.minSchoolPlanNum > 200
                                ? "You have more than 200 students in your classes, please try District Plan"
                                : "Please enter a number between " +
                                  _vm.minSchoolPlanNum +
                                  "-200"
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.isSchoolPlanItem && _vm.numberOfMembers > 200
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "margin-top": "5px",
                          "line-height": "15px",
                        },
                      },
                      [
                        _c("a", { on: { click: _vm.guide2District } }, [
                          _vm._v(
                            "For over 200 members, please contact us for District Plan"
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.isSchoolPlanItem
                  ? _c("div", { staticStyle: { "margin-top": "10px" } }, [
                      _c("div", { staticClass: "price-tips" }, [
                        _vm._v("Enter total number of students and teachers."),
                      ]),
                    ])
                  : _vm._e(),
                _vm.checkNum(_vm.numberOfMembers) && false
                  ? _c("div", { staticClass: "price-tips" }, [
                      _vm._v(" *Include teachers and students, average "),
                      _c("span", { staticStyle: { color: "#393232" } }, [
                        _c("span", [_vm._v("$")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.toThousands(
                                (_vm.numberOfMembers > 0
                                  ? _vm.originalPrice / _vm.numberOfMembers
                                  : 0
                                ).toFixed(2)
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm._v(" per member per year. "),
                    ])
                  : _vm._e(),
              ])
            : _vm.payItem == "FAMILY_PLAN"
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "product-name",
                    staticStyle: { width: "100%", "margin-bottom": "5px" },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.productName) + " "),
                    _c("span", { staticStyle: { "font-weight": "400" } }, [
                      _vm._v("/One Year"),
                    ]),
                  ]
                ),
                _vm._m(0),
                _vm.checkNum(_vm.numberOfMembers) && false
                  ? _c(
                      "div",
                      {
                        staticClass: "price",
                        staticStyle: {
                          "margin-top": "6px",
                          "font-size": "13px",
                          color: "#000",
                        },
                      },
                      [
                        _c("span", [_vm._v("$")]),
                        _vm._v(
                          " " + _vm._s(_vm.toThousands(_vm.originalPrice)) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.checkNum(_vm.numberOfMembers)
                  ? _c(
                      "div",
                      {
                        class: {
                          tips: 1,
                          "tips-red": !_vm.checkNum(_vm.numberOfMembers),
                        },
                      },
                      [_vm._v(' "Please enter a number between 2-5" ')]
                    )
                  : _vm._e(),
                false && _vm.checkNum(_vm.numberOfMembers)
                  ? _c("div", { staticStyle: { "margin-top": "10px" } }, [
                      _c("div", { staticClass: "price-tips" }, [
                        _vm._v(" 2 children "),
                        _c("span", [_vm._v("$")]),
                        _vm._v(
                          " " + _vm._s(_vm.priceForFamily1) + " (30% off) × 2 "
                        ),
                        _c("div", { staticClass: "right-price" }, [
                          _c("span", [_vm._v("$")]),
                          _vm._v(
                            " " +
                              _vm._s((_vm.priceForFamily1 * 2).toFixed(2)) +
                              " "
                          ),
                        ]),
                      ]),
                      _vm.numberOfMembers > 2
                        ? _c("div", { staticClass: "price-tips" }, [
                            _vm._v(" From 3 to 5 children "),
                            _c("span", [_vm._v("$")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.priceForFamily2) +
                                " (40% off) × " +
                                _vm._s(_vm.numberOfMembers - 2) +
                                " "
                            ),
                            _c("div", { staticClass: "right-price" }, [
                              _c("span", [_vm._v("$")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (
                                      _vm.priceForFamily2 *
                                      (_vm.numberOfMembers - 2)
                                    ).toFixed(2)
                                  ) +
                                  " "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : _c("div", [
                _c("div", { staticClass: "product-name" }, [
                  _vm._v(_vm._s(_vm.productName)),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "price",
                    staticStyle: { width: "91px", float: "right" },
                  },
                  [
                    _c("span", [_vm._v("$")]),
                    _vm._v(
                      " " + _vm._s(_vm.toThousands(_vm.originalPrice)) + " "
                    ),
                  ]
                ),
              ]),
          _c("div", { staticClass: "checkout-line" }),
          !_vm.isMonthPlan && _vm.checkNum(_vm.numberOfMembers)
            ? _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.couponCode,
                      expression: "couponCode",
                    },
                  ],
                  staticClass: "coupon-code",
                  style: {
                    "box-shadow": _vm.byTips1,
                    "border-color": _vm.couponnotice.IsRight ? "" : "#FF9592",
                  },
                  attrs: { placeholder: _vm.tips1, type: "text" },
                  domProps: { value: _vm.couponCode },
                  on: {
                    blur: function ($event) {
                      _vm.tips1 = "Coupon Code(Optional)"
                    },
                    keyup: [
                      function ($event) {
                        _vm.couponnotice.IsRight = true
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.checkCoupon.apply(null, arguments)
                      },
                    ],
                    focus: function ($event) {
                      _vm.tips1 = ""
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.couponCode = $event.target.value
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    class: {
                      "coupon-apply": 1,
                      "coupon-apply-grey": _vm.couponCode.trim().length <= 0,
                    },
                    on: { click: _vm.checkCoupon },
                  },
                  [_vm._v(" " + _vm._s(_vm.btnName) + " ")]
                ),
                _vm.couponMinus > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "right-price",
                        staticStyle: {
                          color: "#ff6666",
                          "font-size": "13px",
                          "line-height": "20px",
                          "margin-top": "3px",
                        },
                      },
                      [
                        _vm._v(" - "),
                        _c("span", [_vm._v("$")]),
                        _vm._v(
                          " " + _vm._s(_vm.toThousands(_vm.couponMinus)) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.couponnotice.IsShow
                  ? _c("div", { staticClass: "price-tips" }, [
                      _vm.couponnotice.text != ""
                        ? _c("span", {
                            staticClass: "tips-red",
                            domProps: {
                              innerHTML: _vm._s(_vm.couponnotice.text),
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.couponMinus > 0 && false
                  ? _c(
                      "div",
                      {
                        staticClass: "price",
                        staticStyle: {
                          "margin-top": "6px",
                          color: "#ff6666",
                          "font-size": "13px",
                          float: "right",
                        },
                      },
                      [
                        _vm._v(" - "),
                        _c("span", [_vm._v("$")]),
                        _vm._v(
                          " " + _vm._s(_vm.toThousands(_vm.couponMinus)) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "checkout-line" }),
              ])
            : _vm._e(),
          (_vm.payItem == "FAMILY_PLAN" || _vm.isSchoolPlanItem) &&
          _vm.membershipDiscount > 0 &&
          _vm.checkNum(_vm.numberOfMembers)
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "inline-block",
                      "line-height": "15px",
                      width: "195px",
                      color: "#666",
                      "font-size": "12px",
                    },
                  },
                  [_vm._v(" Your existing membership balance deduction: ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "price",
                    staticStyle: {
                      "margin-top": "6px",
                      color: "#ff6666",
                      "font-size": "13px",
                      float: "right",
                    },
                  },
                  [
                    _vm._v(" - "),
                    _c("span", [_vm._v("$")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.toThousands(_vm.membershipDiscount)) +
                        " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "checkout-line" }),
              ])
            : _vm._e(),
          _vm.checkNum(_vm.numberOfMembers)
            ? _c("div", { staticClass: "total-price" }, [
                _vm._v(" Total: "),
                _c("span", [_vm._v("$")]),
                _vm._v(
                  " " +
                    _vm._s(_vm.toThousands(_vm.finalPrice)) +
                    " (￥" +
                    _vm._s(
                      _vm.toThousands((_vm.finalPrice * _vm.rate).toFixed(2))
                    ) +
                    ") "
                ),
              ])
            : _c("div", { staticClass: "total-price" }, [
                _vm._v("Total: ----"),
              ]),
          _vm.regularPrice > _vm.finalPrice &&
          _vm.checkNum(_vm.numberOfMembers) &&
          false
            ? _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "right",
                    "font-size": "12px",
                    color: "#a7a6a6",
                    "margin-top": "2px",
                  },
                },
                [
                  _vm._v(" You've saved: "),
                  _c("span", { staticStyle: { color: "#ff6666" } }, [
                    _c("span", [_vm._v("$")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.toThousands(
                            (_vm.regularPrice - _vm.finalPrice).toFixed(2)
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "choose-pay-mode-wrap" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.$t("members.shop.paymentMethod")) + " "),
            ]),
            _c(
              "div",
              {
                staticClass: "options",
                style: { pointerEvents: _vm.isPaying ? "none" : "auto" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "option mt5",
                    on: {
                      click: function ($event) {
                        return _vm.onCreditCardPayClick()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "choose-pay-img",
                      attrs: { src: "/assets/images/pay/new/creditcard.png" },
                    }),
                    _vm._v(" Credit Card "),
                  ]
                ),
                _c("div", {
                  staticClass: "option mt5",
                  staticStyle: {
                    "padding-left": "0px",
                    border: "none",
                    width: "155px",
                  },
                  attrs: { id: "paypal-button-container" },
                }),
                _vm.enablePoPay
                  ? _c(
                      "div",
                      {
                        staticClass: "option mt10",
                        on: { click: _vm.onPoPayClick },
                      },
                      [
                        _c("img", {
                          staticClass: "choose-pay-img",
                          attrs: { src: "/assets/images/pay/new/check.png" },
                        }),
                        _vm._v(" PO Pay "),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
          !_vm.isPaying
            ? _c(
                "div",
                { staticClass: "btn cancel-button", on: { click: _vm.close } },
                [_vm._v("Cancel")]
              )
            : _c(
                "div",
                [
                  _c("spinner", {
                    attrs: {
                      top: "15",
                      text: "Checking the payment result, please wait",
                    },
                  }),
                ],
                1
              ),
          _c("div", { staticClass: "product-desc" }, [
            _vm._v(_vm._s(_vm.description)),
          ]),
          !_vm.User.isTeacher
            ? _c("div", { staticClass: "order-notice" }, [
                _vm._v(
                  " By clicking Place Your Order you acknowledge that you are 18 years or older or have permission from your parent or legal guardian to purchase and that the terms of Storybird's Refund Policy will govern your purchase. All sales are final. "
                ),
              ])
            : _c("div", { staticClass: "order-notice" }, [
                _vm._v(
                  " By clicking Place Your Order you acknowledge that you are 18 years or older and that the terms of Storybird's Refund Policy will govern your purchase. All sales are final. "
                ),
              ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.poPayDialogVisible,
            width: "650px",
            top: "30vh",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.poPayDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "line-height": "24px",
                    "margin-bottom": "20px",
                  },
                },
                [
                  _vm._v(
                    " You are using PO payment, please complete the form information "
                  ),
                ]
              ),
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.poForm,
                    "label-width": "110px",
                    inline: "",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Item" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.poForm.Item,
                          callback: function ($$v) {
                            _vm.$set(_vm.poForm, "Item", $$v)
                          },
                          expression: "poForm.Item",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Members" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.poForm.members,
                          callback: function ($$v) {
                            _vm.$set(_vm.poForm, "members", $$v)
                          },
                          expression: "poForm.members",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "UserName",
                        prop: "InvoiceUserName",
                        required: "",
                        error: "required",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "full name" },
                        model: {
                          value: _vm.poForm.InvoiceUserName,
                          callback: function ($$v) {
                            _vm.$set(_vm.poForm, "InvoiceUserName", $$v)
                          },
                          expression: "poForm.InvoiceUserName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "SchoolName",
                        prop: "InvoiceSchoolName",
                        required: "",
                        error: "required",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "school name" },
                        model: {
                          value: _vm.poForm.InvoiceSchoolName,
                          callback: function ($$v) {
                            _vm.$set(_vm.poForm, "InvoiceSchoolName", $$v)
                          },
                          expression: "poForm.InvoiceSchoolName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "button-standard",
                  on: {
                    click: function ($event) {
                      _vm.poPayDialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "button-standard button-blue",
                  attrs: { type: "primary" },
                  on: { click: _vm.onPoPayContinueClick },
                },
                [_vm._v("Continue")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "family-price-wrap" }, [
      _c("div", { staticClass: "family-price-text" }, [
        _vm._v("1 Parent Account + 5 Children Accounts"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }