import store from "@/store/index.js";
import i18n from "@/i18n/index";

// 过滤指定url
const excludeUrl = ["/api/Invitation/Check", "/api/UserData/UpdateReadingHistory", "/api/Writes/ArtworkCount"];
export function showLoginAlert(url) {
  if (excludeUrl.includes(url)) {
    return false;
  }

  // 先判断是否已有alert存在
  let alertNode = document.getElementById("_layout_to_login_alert");
  if (alertNode) {
    return true;
  }

  let container = document.createElement("div");
  container.id = "_layout_to_login_alert";
  container.className = "alert-login-container";

  // confirm btn
  let btn = document.createElement("div");
  btn.className = "alert-login-btn-confirm";
  btn.innerHTML = i18n.t("common.ok");
  btn.onclick = function () {
    store.dispatch("user/logout");
    container.remove();
  };

  // btn parent
  let btnParent = document.createElement("div");
  btnParent.className = "alert-login-btns-parent";
  btnParent.appendChild(btn);

  // msg
  let msg = document.createElement("div");
  msg.className = "alert-login-msg";
  msg.innerHTML = "Your login has expired, please login again";

  // content layout
  let content = document.createElement("div");
  content.className = "alert-login-content";
  content.appendChild(msg);
  content.appendChild(btnParent);

  container.appendChild(content);
  document.body.appendChild(container);

  return true;
}
