export function notifySuccess(msg, title = "Success", options = {}) {
  const opts = { position: "bottom-right", ...options };
  ELEMENT &&
    ELEMENT.Notification &&
    ELEMENT.Notification({
      type: "success",
      title: title,
      message: msg,
      ...opts,
    });
}

// 显示老师问卷通知
export function showTeacherSurvey(url) {
  const str = localStorage.getItem("storybird-global") || "{}";
  const conf = JSON.parse(str);
  if (conf.shownSurvey) {
    return;
  }

  ELEMENT &&
    ELEMENT.Notification &&
    ELEMENT.Notification({
      title: "Feedback Survey",
      dangerouslyUseHTMLString: true,
      message: `Got a minute? Your feedback is important to us.&nbsp;<i><a href="${url}" target="_blank">Click here.</a></i>`,
      position: "bottom-right",
      duration: 0,
      offset: 100,
      onClose: () => {
        saveSurveyState();
      },
    });
}

// 显示自定义icon通知
export function notifyIconMsg(msg, params = {}) {
  const options = {
    title: "",
    dangerouslyUseHTMLString: true,
    message: msg,
    position: "top-right",
    offset: 100,
    ...params,
  };
  ELEMENT && ELEMENT.Notification && ELEMENT.Notification(options);
}

function saveSurveyState() {
  const str = localStorage.getItem("storybird-global") || "{}";
  const conf = JSON.parse(str);
  conf.shownSurvey = true;
  localStorage.setItem("storybird-global", JSON.stringify(conf));
}
