import request from "@/utils/request";
import qs from "qs";

export function UploadArtwork(params) {
  return request({
    url: `/api/Upload/Artwork`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function UploadDeleteArtwork(params) {
  return request({
    url: `/api/Upload/DeleteArtwork`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetUploadArtworkToken(params) {
  return request({
    url: "/api/Upload/GetUploadArtworkToken",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function Artwork(params) {
  return request({
    url: "/api/Upload/Artwork",
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetToken(params) {
  return request({
    url: `/api/Upload/GetToken`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}
export function ComicCover(params) {
  return request({
    url: `/api/Upload/ComicCover`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}

export function GetUploadToken(params) {
  return request({
    url: `/api/Upload/GetUploadToken`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}
