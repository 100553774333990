import Cookies from "js-cookie";

const TokenKey = "X-Token";
const ExpireBarCloseKey = "ExpireBarClose";
const InviteCodeKey = "InviteCode";
const CurrentClassOpenIdKey = "CurrentClassOpenId";
const InviteClassPasscodeKey = "InviteClassPasscode";
const SharePasswordKey = "SharePassword";
const GoogleClientTokenKey = "GoogleClientToken";
const closeExpressTipKey = "closeExpressTip";

import { isEmptyString } from "@/utils/validate.js";

export function getCookieByKey(key) {
  return Cookies.get(key);
}

export function getToken() {
  const token = Cookies.get(TokenKey);
  if (isEmptyString(token)) {
    return null;
  }
  return token;
}

export function setToken(token) {
  if (!token) {
    removeStbCookie();
    Cookies.remove(TokenKey);
    return;
  }
  return Cookies.set(TokenKey, token);
}

export function delGoogleClientToken() {
  Cookies.remove(GoogleClientTokenKey);
}

export function getSharePassword() {
  return Cookies.get(SharePasswordKey);
}

export function setSharePassword(value) {
  if (!value) {
    Cookies.remove(SharePasswordKey);
    return;
  }
  return Cookies.set(SharePasswordKey, value);
}

export function getExpireBarClose() {
  return Cookies.get(ExpireBarCloseKey);
}

export function setExpireBarClose(val) {
  return Cookies.set(ExpireBarCloseKey, val);
}

export function getInviteCode() {
  return Cookies.get(InviteCodeKey);
}

export function setInviteCode(val) {
  return Cookies.set(InviteCodeKey, val);
}

export function setCurrentClassOpenId(val) {
  if (!val) {
    Cookies.remove(CurrentClassOpenIdKey);
    return;
  }
  return Cookies.set(CurrentClassOpenIdKey, val);
}

export function getCurrentClassOpenId() {
  return Cookies.get(CurrentClassOpenIdKey);
}

export function setInviteClassPasscode(val) {
  return Cookies.set(InviteClassPasscodeKey, val);
}

export function getInviteClassPasscode() {
  return Cookies.get(InviteClassPasscodeKey);
}

export function saveCookie(key, value, options) {
  return Cookies.set(key, value, options);
}

export function deleteCookie(key) {
  Cookies.remove(key);
}

export function getcloseExpressTip() {
  return Cookies.get(closeExpressTipKey);
}

export function setcloseExpressTip(val) {
  return Cookies.set(closeExpressTipKey, val);
}

// 最初storybird 身份cookie
export function getStbCookie() {
  let jwtKey = "";
  let host = window.location.host;
  if (host.indexOf("localhost.storybird.com") >= 0 || host.indexOf("dev.storybird.com") >= 0) {
    jwtKey = "storybird_jwt_dev";
  } else {
    jwtKey = "storybird_jwt";
  }
  jwtKey = jwtKey + "_info";
  let cookie = Cookies.get(jwtKey);
  if (isEmptyString(cookie)) {
    return null;
  }
  return cookie;
}

export function removeStbCookie() {
  let jwtKey = "";
  let host = window.location.host;
  if (host.indexOf("localhost.storybird.com") >= 0 || host.indexOf("dev.storybird.com") >= 0) {
    jwtKey = "storybird_jwt_dev";
  } else {
    jwtKey = "storybird_jwt";
  }
  jwtKey = jwtKey + "_info";
  Cookies.remove(jwtKey, { path: "/", domain: ".storybird.com" });
  // Cookies.set(jwtKey, "", { path: "/", domain: ".storybird.com" });
}

function getStbSignCookie() {
  let jwtKey = "";
  let host = window.location.host;
  if (host.indexOf("localhost.storybird.com") >= 0 || host.indexOf("dev.storybird.com") >= 0) {
    jwtKey = "storybird_jwt_dev_sign";
  } else {
    jwtKey = "storybird_jwt_sign";
  }
  jwtKey = jwtKey + "_info";
  let cookie = Cookies.get(jwtKey);
  return cookie;
}

export function getAuthorizationCookies() {
  return getStbCookie() + "." + getStbSignCookie();
}
