import { getAuthorizationCookies } from "@/utils/auth.js";
import { showLoginAlert } from "@/utils/alert.js";
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_HOST,
  // baseURL: '/v1',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // 必须加上空值，否则接口报错
    if (config.url.indexOf("/h5/v2/") > 0) {
      config.headers["authorization"] = getAuthorizationCookies();
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    if (typeof res == "string") {
      let obj = {
        code: -1000,
        msg: res,
        message: "server error",
        result: null,
      };
      return Promise.reject(obj);
    } else if ("code" in res && res.code === 0) {
      return res;
    } else if (response && response.config && response.config.url && response.config.url.indexOf("/v2/") > 0) {
      // go v2 版本接口返回结果没有code字段，只有内容
      return res;
    } else {
      let msg = res.msg || "";
      if (res.code === 404 && msg === "") {
        res.msg = "This page does not exist, or you do not have permission to view it！";
      }
      msg = msg.toLowerCase();
      // code = 100004 是go接口返回
      if (
        msg.indexOf("not login in") >= 0 ||
        msg.indexOf("not login") >= 0 ||
        msg.indexOf("not sign") >= 0 ||
        res.code === 100004
      ) {
        let showLogin = showLoginAlert(response.config.url);
        if (!showLogin) {
          return Promise.reject(res);
        }
        return;
      }
      return Promise.reject(res);
    }
  },
  (error) => {
    if (error.response && error.response.data) {
      let errObj = {
        code: error.response.status,
        message: error.response.data.message,
        msg: "server error",
        result: null,
      };
      return Promise.reject(errObj);
    }
    let errObj = {
      code: -1000,
      message: error.message,
      msg: "server error",
      result: null,
    };
    return Promise.reject(errObj);
  },
);

export default service;
